import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { MatchItem } from 'components';
import { useWithDispatch } from 'hooks';
import { setBookingSource, setMixpanelStore } from 'store/common/actions';
import { Tracking } from 'services';
import { trackEvent, MP_EVENTS, isMatchedStylist, MP_PROPS, MP_VALUES } from 'services/mixpanel';
import { useCommonStore } from 'store/common/reducer';
import { useBookingStore } from 'store/booking/reducer';
import { useHistory } from 'react-router-dom';
import { Stylist as StylistType } from 'types/user';
import { useUserStore } from 'store/user/reducer';
import { updateFavoriteStylist } from 'store/user/actions';
import { match as texts } from 'content.json';
import MatchHeader from '../components/Header';

interface IStylistsLis {
    matchedStylists: StylistType[];
    moreStylists: StylistType[];
}

export const StylistsList: React.FC<IStylistsLis> = ({ matchedStylists, moreStylists }) => {
    const setBookingSourceAction = useWithDispatch(setBookingSource);
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const matches = useCommonStore((store) => store.stylistsMatchs);
    const campaign = useBookingStore((store) => store.campaign);
    const stylists = useCommonStore((store) => store.stylists);
    const history = useHistory();
    const favoriteStylists = useUserStore((store) => store.favoriteStylists);
    const updateFavoriteStylistAction = useWithDispatch(updateFavoriteStylist);
    const setMixpanelStoreAction = useWithDispatch(setMixpanelStore);

    const isFavoriteStylists = (uuid: string) =>
        favoriteStylists?.findIndex((favorite) => favorite.uuid === uuid) > -1;
    const analyticsTrackingEvent = (stylistName: string, uuid: string, matches: StylistType[]) => {
        Tracking.tag({
            event: 'eec.impressionClick',
            ecommerce: {
                click: {
                    actionField: { list: 'Stylist Recommendations' },
                    products: [
                        {
                            id: uuid,
                            name: stylistName,
                            category: '/category/stylist/'
                        }
                    ]
                }
            }
        });
    };

    const mixpanelTrackingEvent = (stylistName: string, uuid: string, matches: StylistType[]) => {
        const eventProperties = {
            ...mixpanelStore.onboarding,
            [MP_PROPS.STYLIST_NAME]: stylistName ?? '',
            [MP_PROPS.STYLIST_UUID]: uuid,
            [MP_PROPS.IS_STYLIST_MATCH]: isMatchedStylist(matches, uuid),
            [MP_PROPS.BOOKING_SOURCE]: MP_VALUES.STYLISTS
        };

        trackEvent({
            name: MP_EVENTS.MEET_STYLIST,
            properties: eventProperties
        });
        setMixpanelStoreAction({
            onboarding: {
                ...eventProperties
            }
        });
    };

    const selectStylist = (stylist: StylistType) => {
        const uuid = stylist?.uuid || stylist?.value;
        setBookingSourceAction({source:MP_VALUES.STYLISTS});
        if (uuid) {
            const stylistName = stylist.name ? stylist.name : stylist.label;
            if (stylistName) {
                analyticsTrackingEvent(stylistName, uuid, matches);
                mixpanelTrackingEvent(stylistName, uuid, matches);
            }
            let path = { pathname: `/stylist/${uuid}/profile`, state: {} };
            if (campaign) path.state = { campaign };
            history.push(path);
        }
    };

    return (
        <>
            <MatchHeader
                matchesLength={matchedStylists.length}
                onSelect={selectStylist}
                stylists={stylists}
            />
            <Row>
                {matchedStylists.map((match) => (
                    <Col xs={12} md={4} key={match.uuid} className="match-item-container">
                        <MatchItem
                            match={match}
                            onClick={selectStylist}
                            isFavorite={isFavoriteStylists(match.uuid ?? '')}
                            onFavorite={updateFavoriteStylistAction}
                        />
                    </Col>
                ))}
            </Row>
            {moreStylists && (
                <>
                    <div className="more-stylists title">{texts.moreHeader}</div>
                    <Row>
                        {moreStylists.map((match) => (
                            <Col xs={12} md={4} key={match.uuid} className="match-item-container">
                                <MatchItem
                                    match={match}
                                    onClick={selectStylist}
                                    isFavorite={isFavoriteStylists(match.uuid ?? '')}
                                    onFavorite={updateFavoriteStylistAction}
                                />
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </>
    );
};
