import './style.scss';

import React, { useEffect, useState } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';

import { Loader, ProfilePhoto } from '../../../components';
import { settings as texts } from '../../../content';
import Breadcrumbs from './Breadcrumbs';
import { capitalize } from 'services/utils/string-utils';

export default ({ loading, user }) => {
    const [info, setInfo] = useState({});

    useEffect(() => {
        if (user) setInfo(user);
    }, [user]);

    return (
        <Container className="settings-loyalty" fluid>
            {loading && <Loader />}
            <Row>
                <Breadcrumbs />
            </Row>
            <Row>
                <label className="section-title">{texts.loyalty.title}</label>
            </Row>
            <Row>
                <Col className="header">
                    <ProfilePhoto user={info} level={true} />
                    <div>
                        <label>
                            {info.level
                                ? texts.loyalty.status
                                      .replace('%name%', info.first_name || '')
                                      .replace('%status%', capitalize(info.level) || '')
                                : texts.loyalty.nostatus}
                        </label>
                        <label>
                            {texts.loyalty.bookings.replace('%count%', info.bookings_count || '')}
                        </label>
                    </div>
                </Col>
            </Row>
            {info.level ? (
                <Row>
                    <Col>
                        <Rewards level={info.level} />
                    </Col>
                </Row>
            ) : (
                Object.keys(texts.loyalty.rewards).map((level) => (
                    <Row key={level}>
                        <Col>
                            <Rewards level={level} general={true} />
                        </Col>
                    </Row>
                ))
            )}
            {info.level && (
                <Row>
                    <Col>
                        <a
                            href="https://go.onelink.me/app/b1d2ad0e"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {texts.loyalty.download}
                        </a>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

const Rewards = ({ level, general = false }) => (
    <div className={`rewards ${general ? 'general' : ''}`}>
        <label>
            {texts.loyalty.rewardTitle.replace('%status%', level || '').substring(general ? 5 : 0)}
        </label>
        <ListGroup>
            {level &&
                texts.loyalty.rewards[level].map((item, index) => (
                    <ListGroup.Item key={`reward-${index}`}>{item}</ListGroup.Item>
                ))}
        </ListGroup>
    </div>
);
