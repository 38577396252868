import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { bookStylist } from 'store/booking/actions';
import { useUserStore } from 'store/user/reducer';
import { loadInbox, selectChannel } from './store/actions';
import Message from './Message';
import { useWithDispatch } from 'hooks';
import { useInboxStore } from './store/reducer';

const Messages: React.FC = () => {
    const hisotory = useHistory();
    const user = useUserStore((store) => store.user);
    const messages = useInboxStore((store) => store.messages);
    const loadInboxAction = useWithDispatch(loadInbox);
    const selectChannelAction = useWithDispatch(selectChannel);
    const bookStylistAction = useWithDispatch(bookStylist);
    useEffect(() => {
        loadInboxAction();
    }, []);

    const onSelect = ({
        sid,
        stylist,
        current_session_status
    }: {
        sid: string;
        stylist: any;
        current_session_status: any;
    }) => {
        if (current_session_status === 'rebook') {
            bookStylistAction({
                ...stylist,
                profile_picture: stylist.picture,
                name: `${stylist.first_name} ${stylist.last_name}`
            });
            hisotory.push('/goals');
        } else {
            selectChannelAction(sid);
            hisotory.push(`/chat/${stylist.uuid}/${user?.user_uuid}`);
        }
    };
    return (
        <Container className="messages">
            {messages.map((message) => (
                <Message key={message.sid} message={message} onClick={onSelect} />
            ))}
        </Container>
    );
};
export default Messages;
