import React, { useEffect } from 'react';
import { Button, Col } from 'react-bootstrap';
import { MP_EVENTS, MP_PROPS, registerProperties, trackEvent } from 'services/mixpanel';

import { StickyButton } from '../../../components';
import Style from '../../../services/Style';

const BodyType = ({
    gender,
    setBodyTypeList,
    data: { question, button },
    bodyTypeList,
    bodyTypes,
    bodyTypesNames,
    selectBodyType,
    nextStep
}) => {
    useEffect(() => {
        getTypes();
        trackEvent({
            name: MP_EVENTS.ONBOARDING_BODY_TYPE_VIEWS,
            properties: {}
        });
    }, []);

    const getTypes = async () => {
        const { data } = await Style.getBodyTypes(gender);
        setBodyTypeList(data);
    };

    return (
        <div className="body-type">
            <Col className="content">
                <p className="question web">{question.web}</p>
                <p className="question mobile">{question.mobile}</p>

                <div className="types">
                    {bodyTypeList.map((type) => (
                        <div
                            key={type.uuid}
                            className="tag"
                            data-selected={bodyTypes[type.uuid] === 1}
                            onClick={() => selectBodyType(type)}>
                            {type.name}
                        </div>
                        /*<RadioButton
                            key={type.uuid}
                            id={`bodyTypeCheckbox_${type.uuid}`}
                            value={type.uuid}
                            isChecked={bodyTypes[type.uuid] === 1}
                            onClick={() => selectBodyType(type)}
                            label={type.name}
                        />*/
                    ))}
                </div>
                <Button
                    variant="warning"
                    id="skip"
                    className="d-none d-sm-flex"
                    onClick={() => {
                        if (Object.keys(bodyTypes).length) {
                            trackEvent({
                                name: MP_EVENTS.ONBOARDING_BODY_TYPE_SELECTION,
                                properties: { [MP_PROPS.BODY_TYPE]: bodyTypesNames }
                            });
                            registerProperties({ [MP_PROPS.BODY_TYPE]: bodyTypesNames });
                        }
                        return nextStep();
                    }}>
                    {Object.keys(bodyTypes).length ? button.next : button.skip}
                </Button>
                <StickyButton
                    className="d-flex d-sm-none"
                    id="skip"
                    onClick={nextStep}
                    text={Object.keys(bodyTypes).length ? button.next : button.skip}
                    type="warning"
                />
            </Col>
        </div>
    );
};

export default BodyType;
