import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';

import {
    clearGiftCard,
    giftCardError,
    updateGiftCardState
} from '../../redux/reducers/payments/actions';
import { refreshUser } from '../../redux/reducers/users/actions';
import Checkout from './Checkout';
import Confirmation from './Confirmation';
import GiftCards from './GiftCards';

const mapStateToProps = ({
    payments: { giftcard, giftcardState, loading, result },
    user: { user }
}) => ({
    giftcard,
    giftcardState,
    loading,
    result,
    user
});

const mapDispatchToProps = (dispatch) => ({
    clearGiftCard: () => dispatch(clearGiftCard()),
    giftCardError: (error) => dispatch(giftCardError(error)),
    updateGiftCardState: (state) => dispatch(updateGiftCardState(state)),
    toggleModal: (data) => toggleModal(dispatch, data),
    refreshUser: () => dispatch(refreshUser())
});

export const GiftCardsMain = connect(mapStateToProps, mapDispatchToProps)(GiftCards);
export const GiftCardsCheckout = connect(mapStateToProps, mapDispatchToProps)(Checkout);
export const GiftCardConfirmation = connect(mapStateToProps, mapDispatchToProps)(Confirmation);
