import './style.scss';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Image, Modal, Row } from 'react-bootstrap';

import { Loader } from '../../../components';
import { modal } from '../../../content';

const Contact = ({ stylist, lastMessage, contact }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [result, setResult] = useState({});

    useEffect(() => {
        if (lastMessage.sid && JSON.parse(lastMessage.body).content === message) {
            setLoading(false);
            setResult({ success: true });
        }
    }, [lastMessage]);

    const onSubmit = async () => {
        setLoading(true);
        try {
            contact(stylist.uuid, message);
        } catch (e) {
            setResult({ failed: true });
            setLoading(false);
        }
    };

    return (
        <span className="contact">
            {loading && <Loader />}

            {result.success ? (
                <div>
                    <Modal.Header closeButton={true} />
                    <Modal.Body>
                        <div className="success">
                            <FontAwesomeIcon icon={faCheck} />
                            <p>
                                <strong>{modal.contact.sent}</strong>
                            </p>
                            <p>{modal.contact.result}</p>
                        </div>
                    </Modal.Body>
                </div>
            ) : (
                <span>
                    <Modal.Header closeButton={true}>
                        <Image className="profile-picture" src={stylist.profile_picture} />
                        <p>{modal.contact.title.replace('%stylist%', stylist.first_name)}</p>
                    </Modal.Header>

                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Control
                                        as="textarea"
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        {result.failed && <p className="error">{modal.errors.message}</p>}
                        <Button variant="warning" disabled={!message.length} onClick={onSubmit}>
                            {modal.contact.submit}
                        </Button>
                    </Modal.Footer>
                </span>
            )}
        </span>
    );
};

export default Contact;
