import React, { useState } from 'react';
import { Card, Col, Collapse, Container, Row } from 'react-bootstrap';

import { match } from '../../content';
import FilterItem from './FilterItem';

const Filter = ({
    filter: { key = '', value = '', filter_options = [] },
    selected = [],
    showSelection = false,
    isCollapsed,
    onClick,
    onChange,
    onClear,
    disabled = false,
    type,
    isfilterSubmited = false
}) => {
    const [showAll, setShowAll] = useState(false);

    return (
        <Card
            className={`filter-row ${isCollapsed && filter_options.length > 0 ? 'active' : ''} ${
                disabled ? 'disabled' : ''
            } ${key.toLowerCase()}`}
        >
            <div className="filter" onClick={() => onClick(isCollapsed ? '' : key)}>
                {value} <span className="expand-sign" />
                {showSelection && (
                    <div className="selection">
                        {selected
                            .map((selection) => {
                                if (selection) return selection.text;
                            })
                            .join(',')}
                    </div>
                )}
            </div>
            <Collapse
                in={key === 'PRICES' ? isCollapsed : isCollapsed && filter_options.length > 0}
            >
                <Card.Body>
                    <Container fluid>
                        {filter_options && filter_options.length > 0 && type === 'checkbox' ? (
                            <span>
                                {filter_options
                                    .slice(0, showAll ? filter_options.length : 7)
                                    .map((item) => {
                                        const isSelected =
                                            selected.filter((filter) => filter.key === item.key)
                                                .length > 0;
                                        return (
                                            <FilterItem
                                                key={item.key}
                                                filterKey={key}
                                                type={type}
                                                isSelected={isSelected}
                                                item={item}
                                                onChange={onChange}
                                                singleSelect={item.single_select}
                                                filterSubmited={isfilterSubmited}
                                            />
                                        );
                                    })}
                                {filter_options.length > 7 && (
                                    <Row>
                                        <Col
                                            className="show-all"
                                            onClick={() => setShowAll(!showAll)}
                                        >
                                            {!showAll
                                                ? match.filters.showAll.replace(
                                                      '%length%',
                                                      filter_options.length
                                                  )
                                                : match.filters.showLess}
                                        </Col>
                                    </Row>
                                )}
                            </span>
                        ) : (
                            <FilterItem
                                key={`${key}_${type}`}
                                filterKey={key}
                                selected={selected}
                                options={filter_options}
                                type={type}
                                onChange={onChange}
                                onClear={onClear}
                                filterSubmited={isfilterSubmited}
                            />
                        )}
                    </Container>
                </Card.Body>
            </Collapse>
        </Card>
    );
};

export default Filter;
