import commonReducer from 'store/common/reducer';
import { name as commonName } from 'store/common/reducer';
import userReducer from 'store/user/reducer';
import { name as userName } from 'store/user/reducer';
import bookingReducer from 'store/booking/reducer';
import { name as bookingName } from 'store/booking/reducer';
import errorReducer from 'store/error/reducer';
import { name as errorName } from 'store/error/reducer';
import paymentsReducer from 'store/payments/reducer';
import { name as paymentsName } from 'store/payments/reducer';
import uiReducer from 'store/ui/reducer';
import { name as uiName } from 'store/ui/reducer';
import servicesReducer from 'store/services/reducer';
import { name as servicesName } from 'store/services/reducer';
import { useReducer } from 'hooks';
// TODO: need to remove this one- only a minor patch for now.
import inboxReducer from 'routes/Inbox/store/reducer';
import { name as inboxName } from 'routes/Inbox/store/reducer';

export const useInitCommonReducer = () => {
    useReducer(commonName, commonReducer);
    useReducer(userName, userReducer);
    useReducer(bookingName, bookingReducer);
    useReducer(errorName, errorReducer);
    useReducer(paymentsName, paymentsReducer);
    useReducer(uiName, uiReducer);
    useReducer(servicesName, servicesReducer);
    useReducer(inboxName, inboxReducer);
};
