import { sentryException } from 'services/SentryLogging';

import { User } from '../../../services';

export const SET_SESSION_ID = 'SET_SESSION_ID';
export const NEXT_QUESTION_START = 'NEXT_QUESTION_START';
export const NEXT_QUESTION_SUCCESS = 'NEXT_QUESTION_SUCCESS';
export const NEXT_QUESTION_FAILED = 'NEXT_QUESTION_FAILED';
export const PREV_QUESTION = 'PREV_QUESTION';
export const RESET_QUIZ = 'RESET_QUIZ';
export const QUIZ_DONE = 'QUIZ_DONE';

export const setSessionId = (session_id) => ({
    type: SET_SESSION_ID,
    payload: { session_id }
});

export const nextQuestionStart = () => ({
    type: NEXT_QUESTION_START
});

export const nextQuestionSuccess = (question) => ({
    type: NEXT_QUESTION_SUCCESS,
    payload: { question }
});

export const nextQuestionFailed = (error) => ({
    type: NEXT_QUESTION_FAILED,
    payload: { error }
});

export const prevQuestion = () => ({
    type: PREV_QUESTION
});

export const quizDone = () => ({
    type: QUIZ_DONE
});

export const resetQuiz = () => ({
    type: RESET_QUIZ
});

export const nextQuestion = (bodyIssuesFlag) => {
    return async (dispatch, getState) => {
        dispatch(nextQuestionStart());
        try {
            const {
                quiz: { session_id }
            } = getState();
            const { data } = await User.quizQuestion(session_id, bodyIssuesFlag);
            dispatch(nextQuestionSuccess(data));
        } catch (e) {
            dispatch(nextQuestionFailed(e));
        }
    };
};

export const replyQuestion = (answer) => {
    return async (dispatch, getState) => {
        dispatch(nextQuestionStart());
        try {
            const {
                quiz: { session_id, questions }
            } = getState();
            const question = [...questions].pop();
            const { data } = await User.quizReply(session_id, question.type, {
                ...answer,
                client: 'web'
            });
            if (data.data.current_quiz_step < data.data.quiz_steps_count) {
                dispatch(nextQuestionSuccess(data.data));
            } else {
                dispatch(quizDone());
            }
        } catch (e) {
            sentryException(e, 'Cannot submit reply to quiz question');
            dispatch(nextQuestionFailed(e));
        }
    };
};
