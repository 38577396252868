import { Dispatch } from 'redux';
import { AuthService, Cookies, User } from 'services';
import { ICredentials } from 'services/auth-service';
import ReduxService from 'services/redux-service';
import {
    trackEvent,
    registerProperties,
    MP_EVENTS,
    MP_PROPS,
    setMixpanelPeopleData,
    resetMixpanelIdentity
} from 'services/mixpanel';
//import { redirectUserLogin } from 'services/utils/user-utils';
import { store } from 'store';
import { setupError } from 'store/error/actions';
import { toggleModal } from 'store/ui/actions';
import { CoreUser, Stylist, SystemUser, Client } from 'types/user';

import { ActionTypes } from './types';
import { getUserToken } from 'services/utils/token-utils';
import { sentryException } from 'services/SentryLogging';
import { Tracking } from '../../services';
import { clearInbox } from 'routes/Inbox/store/actions';

const registerSuperProperties = (user: Client, isSignUp: boolean) => {
    registerProperties({
        [MP_PROPS.UUID]: user.user_uuid ?? '',
        [MP_PROPS.COUNTRY]: user.country ?? '',
        [MP_PROPS.FIRST_NAME]: user.first_name ?? '',
        [MP_PROPS.LAST_NAME]: user.last_name ?? ''
    });
    isSignUp
        ? registerProperties({
              [MP_PROPS.SIGNUP_PLATFORM]: 'web',
              [MP_PROPS.SIGNUP_DATE]: new Date(Date.now()).toDateString()
          })
        : registerProperties({
              [MP_PROPS.GENDER]: user.gender,
              [MP_PROPS.USER_TYPE]: user.is_stylist ? 'stylist' : 'user',
              [MP_PROPS.ORDER_COUNT]: user.counters.purchases.toString(),
              [MP_PROPS.BOOKING_COUNT]: user.bookings_count,
              [MP_PROPS.USER_STATUS]: user.level ?? ''
          });
};

export const logoutUser = (dispatch: Dispatch) =>
    dispatch({
        type: ActionTypes.LOGOUT_USER
    });
export const toggleFavoriteStylist = (dispatch: Dispatch, stylist: Stylist) =>
    dispatch({
        type: ActionTypes.TOGGLE_STYLIST,
        payload: stylist
    });
export const updateUser = (dispatch: Dispatch, user: CoreUser) => {
    dispatch({
        type: ActionTypes.UPDATE_USER,
        payload: user
    });
    toggleModal(dispatch, { type: null });
};

const updateUserDetailsOnLogin = (
    dispatch: Dispatch,
    user: any,
    isUserHasSessions: boolean,
    source?: string
) => {
    registerSuperProperties(user, false);
    if (source === 'form')
        trackEvent({
            name: MP_EVENTS.LOGIN,
            properties: {}
        });
    updateUser(dispatch, user);
    //redirectUserLogin(isUserHasSessions);
};

export const login = async (
    dispatch: Dispatch,
    credentials: Partial<ICredentials>,
    source?: string
) => {
    try {
        const user = await AuthService.login(credentials);
        let isUserHasSessions = false;

        if (user) {
            const sessionRequest = await User?.getActiveSession(user?.user_uuid);
            isUserHasSessions = sessionRequest?.data?.has_active_sessions;
            updateUserDetailsOnLogin(dispatch, user, isUserHasSessions, source);
            return user;
        } else {
            dispatch({
                type: ActionTypes.LOGOUT_USER,
                payload: null
            });
            toggleModal(dispatch, { type: null });
        }
    } catch (err) {
        setupError(dispatch, {
            error: err,
            message: (err as any)?.message,
            errors: (err as any).errors
        });
    }
};
export const logout = (dispatch: Dispatch) => {
    AuthService.logout();
    logoutUser(dispatch);
    clearInbox(dispatch);
    resetMixpanelIdentity();
};
export const signup = async (dispatch: Dispatch, credentials: ICredentials) => {
    try {
        const user = await AuthService.signup(credentials);
        updateUser(dispatch, user);
        setMixpanelPeopleData(user);
        trackEvent({
            name: MP_EVENTS.SIGNUP_COMPLETED,
            properties: {
                'Signup method': credentials.email
                    ? 'email'
                    : credentials.googleAuthCode
                    ? 'google'
                    : 'unknown'
            }
        });

        registerSuperProperties(user, true);
        Tracking.google({
            type: 'event',
            event: 'sign_up',
            data: {
                user_full_name: ''.concat(user.firstName, ' ', user.lastName),
                user_uuid: user.uuid,
                user_email: user.email
            }
        });
    } catch (err) {
        setupError(dispatch, {
            error: err,
            message: (err as any)?.message,
            errors: (err as any).errors
        });
    }
};
export const loadFavoriteStylists = async (dispatch: Dispatch, params = { from: 0, count: 30 }) => {
    const user = Cookies.get('user');
    if (!user) return;
    await ReduxService.fetch({
        dispatch,
        targetAction: ActionTypes.LOAD_FAVORITE_STYLISTS,
        url: User.urls.favorites({ uuid: user.uuid }),
        params,
        config: { headers: { token: getUserToken() } },
        prettifyData: ({ quota_max, items }: { quota_max: number; items: any[] }) => items
    });
};
export const updateGeneralData = async (dispatch: Dispatch, data: SystemUser) => {
    try {
        await User.update(data);
        const { data: newUser } = await User.get(data.user_uuid);
        updateUser(dispatch, newUser);
        return;
    } catch (err) {
        sentryException(err as Error, 'Cannot update general user data');
        setupError(dispatch, {
            error: err,
            message: (err as any)?.message,
            errors: (err as any).errors
        });
    }
};
export const updateProfilePicture = async (
    dispatch: Dispatch,
    files: FileList,
    { user_uuid, token }: { user_uuid: string; token: string }
) => {
    try {
        const formData = new FormData();

        formData.append('picture', files[0], files[0].name);
        await User.profilePicture({
            uuid: user_uuid,
            token: token,
            data: formData
        });
        const { data } = await User.get(user_uuid);
        updateUser(dispatch, data);
        return;
    } catch (err) {
        sentryException(err as Error, "Cannot update user's profile picture");
    }
};

export const updateFavoriteStylist = async (dispatch: Dispatch, stylist: Stylist) => {
    try {
        const user = Cookies.get('user');
        if (user) {
            const favoriteStylists = store.getState().user.favoriteStylists;
            const isFavorite = favoriteStylists.find(
                (favorite: Stylist) => favorite.uuid === stylist.uuid
            );
            toggleFavoriteStylist(dispatch, stylist);

            await (User[isFavorite == undefined ? 'favorite' : 'unfavorite'] as any)({
                user_uuid: user.uuid,
                stylist_uuid: stylist.uuid
            });
        }
    } catch (e) {
        sentryException(e as Error, 'Cannot update favorite stylist');
    }
};

export const updateFavoriteSection = (dispatch: Dispatch, section: string) => {
    dispatch({
        type: ActionTypes.UPDATE_FAVORITE_SECTION,
        payload: section
    });
};
