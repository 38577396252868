import { connect } from 'react-redux';

import { resetOnboarding } from '../../redux/reducers/onboarding/actions';
import OnBoardingIntro from './OnBoardingIntro';

const mapStateToProps = ({ onboarding: { steps } }) => ({
    steps
});

const mapDispatchToProps = (dispatch) => ({
    resetOnboarding: () => dispatch(resetOnboarding())
});

export default connect(mapStateToProps, mapDispatchToProps)(OnBoardingIntro);
