import './style.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import { Page } from '../../components';
import Sections from './Sections';

const GiftCards = () => (
    <Page className="gift-cards">
        <Container fluid>
            <Sections.Header />
            <Container>
                <Sections.Steps />
                <Sections.Info />
                <Sections.Customers />
            </Container>
        </Container>
    </Page>
);

export default GiftCards;
