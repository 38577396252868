import './style.scss';

import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
//import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Image, InputGroup, Row } from 'react-bootstrap';

import { CreditCardElement, Loader } from '../../../components';
import { payment, settings as texts } from '../../../content';
import { Payments } from '../../../services';
import Breadcrumbs from './Breadcrumbs';

//const stripePromise = loadStripe(Payments.getStripeKey());

const Method = ({ user, cards, getCards, error, updatePaymentMethod }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [showError, setShowErrorError] = useState();
    const [valid, setValid] = useState();

    /*useEffect(() => {
        const stripe = require('stripe')(Payments.getStripeKey());
        stripe.applePayDomains.create({ domain_name: 'wishi.me' });
    }, []);*/

    useEffect(() => {
        if (user) {
            setLoading(true);
            getCards();
        }
    }, [user]);

    useEffect(() => {
        setLoading(false);
    }, [cards]);

    useEffect(() => {
        if (error && error.payment) {
            setShowErrorError(true);
            setLoading(false);
        }
    }, [error]);

    const onSubmit = async () => {
        let stripe_token;
        let result;
        try {
            setLoading(true);
            const element = elements.getElement(CardElement);
            if (element) {
                result = await stripe.createToken(element);
                if (result.token) stripe_token = result.token.id;
            }
            updatePaymentMethod(stripe_token);
        } catch (e) {
            setLoading(false);
            setShowErrorError(true);
        }
    };

    return (
        <Container className="settings-payment-method" fluid>
            {loading && <Loader />}
            <Row>
                <Col className="header">
                    <Breadcrumbs />
                    <label>{texts.method.title}</label>
                    <p>{texts.method.description}</p>
                </Col>
            </Row>
            <Form>
                {cards.length > 0 && (
                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>{texts.method.current}</Form.Label>
                                <InputGroup>
                                    <Image
                                        src={Payments.paymentCardType(payment, cards)}
                                        onError={() => payment.cards.default}
                                    />
                                    <Form.Control
                                        className="last4"
                                        type="text"
                                        value={cards[0].last4}
                                        disabled
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            {cards[0].exp_month}/{cards[0].exp_year}
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                )}
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>{texts.method.new}</Form.Label>
                            <CreditCardElement
                                className={showError ? 'card-field-error' : ''}
                                onChange={(e) => {
                                    setShowErrorError(!!e.error);
                                    setValid(!e.error && e.complete);
                                }}
                            />
                            {showError && <p className="card-error">{texts.method.error}</p>}
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Button variant="dark" className="save-button" onClick={onSubmit} disabled={!valid}>
                    {texts.method.save}
                </Button>
            </Form>
        </Container>
    );
};

export default (props) => (
    //<Elements stripe={stripePromise}>
    <Method {...props} />
    //</Elements>
);
