import React from 'react';
import { ModalsKeys } from 'types/helpers';

import { IActionWithPayload } from '../types';

export enum ActionTypes {
    SET_MODAL = 'ui/SET_MODAL',
    TOGGLE_MODAL = 'ui/TOGGLE_MODAL',
    CHANGE_MODAL = 'ui/CHANGE_MODAL',
    SET_LANDING_TYPE = 'ui/SET_LANDING_TYPE',
    SHOW_TOAST = 'ui/SHOW_TOAST',
    HIDE_TOAST = 'ui/HIDE_TOAST'
}
export interface ModalData {
    type: ModalsKeys | null;
    url?: string;
    data?: any | null;
}

export type TToastData = {
    content: React.ReactNode;
    isVisible: boolean;
    onUndo?: () => void;
};

export interface UIState {
    indicators: {
        loading: boolean;
        error: any | null;
        showModal: boolean;
    };
    redirectUrl: string | null;
    modalType: ModalsKeys | null;
    modalData: ModalData | null;
    landingType: string;
    toastData: Partial<TToastData>;
}

export type Actions =
    | IActionWithPayload<ActionTypes.SET_MODAL, boolean>
    | IActionWithPayload<ActionTypes.TOGGLE_MODAL, ModalData>
    | IActionWithPayload<ActionTypes.CHANGE_MODAL, { type: ModalsKeys; data: any }>
    | IActionWithPayload<ActionTypes.SET_LANDING_TYPE, string>
    | IActionWithPayload<ActionTypes.SHOW_TOAST, Partial<TToastData>>
    | IActionWithPayload<ActionTypes.HIDE_TOAST, void>;
