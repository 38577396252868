import { useIsMobile } from 'hooks';
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PlansStructure } from 'types/plans';

import { StylingButton } from '../../../components';

interface IPlans {
    data: PlansStructure;
}

const Plans: React.FC<IPlans> = ({ data }) => {
    const isMobile = useIsMobile();
    return (
        <Row className="landing-plans">
            <Col>
                <Container>
                    <Row>
                        <Col>
                            <p className="section-title">{data.title}</p>
                        </Col>
                    </Row>
                    <Row>
                        {data.plans.map((plan) => (
                            <Col xs={12} sm={4} key={plan.title}>
                                <Card>
                                    <Link to={isMobile ? '/onboarding' : '/pricing'}>
                                        <Card.Body>
                                            <p className="name">{plan.title}</p>
                                            <p className="text">{plan.text}</p>
                                            <p className="price">
                                                {data.currency}
                                                {plan.price}
                                            </p>
                                            <hr />
                                            <p className="link">
                                                {isMobile ? data.styling : data.more}
                                            </p>
                                        </Card.Body>
                                    </Link>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <StylingButton className="d-none d-sm-flex" />
                </Container>
            </Col>
        </Row>
    );
};
export default Plans;
