import axios from 'axios';
import Config from './Config';
import { getUserToken } from './utils/token-utils';

const Payments = {
    getCoupon: (couponCode, token) =>
        axios.get(`${Config.get('apiroot2')}coupon/${couponCode}`, { headers: { token } }),
    getInvoice: (plan, couponCode, token) =>
        axios.get(`${Config.get('apiroot2')}checkout/${plan}`, {
            params: { coupon_code: couponCode },
            headers: { token }
        }),
    payInvoice: (data, model, token) =>
        axios.post(
            `${Config.get('apiroot2')}${model === 'onetime' ? 'charge' : 'subscription'}`,
            data,
            { headers: { token } }
        ),
    payGiftCard: (data) => axios.post(`${Config.get('apiroot2')}invoice/payGiftCard`, data),
    giftCardInvoice: (data, token) =>
        axios.post(
            `${Config.get('apiroot2')}invoice/payGiftCard`,
            { data },
            { headers: { token } }
        ),
    getCards: (uuid, token) =>
        axios.get(`${Config.get('api-gw')}user/${uuid}/cards`, { headers: { token } }),
    updatePaymentMethod: ({ user_uuid, data, token }) =>
        axios.post(`${Config.get('api-gw')}user/${user_uuid}/cards`, data, {
            headers: { token }
        }),
    getStripeKey: () => process.env.REACT_APP_STRIPE,
    payTip: (data, token) =>
        axios.post(`${Config.get('apiroot2')}invoice/payTip`, data, { headers: { token } }),
    checkoutList: (data, token) =>
        axios.post(`${Config.get('apiroot2')}checkoutList`, data, { headers: { token } }),
    checkoutListPay: (data, token) =>
        axios.post(`${Config.get('apiroot2')}checkoutList/pay`, data, { headers: { token } }),
    getCountries: (token) => axios.get(`${Config.get('apiroot2')}country`, { headers: { token } }),
    getStates: (country, token) =>
        axios.get(`${Config.get('apiroot2')}state`, { params: { country }, headers: { token } }),
    sessions: async (user_uuid) => {
        const resp = await axios.get(`${Config.get('apiroot2')}user/${user_uuid}/sessionsPayments`, {
            headers: { token: getUserToken() }
        })
        // hack to overcome backend bug that is returning 200 with error message in body if list is empty
        return Array.isArray(resp.data) ? resp.data : []
    },
    paymentCardType: (payment = {}, cards = []) =>
        payment.cards[cards[0].brand.toLowerCase().replace(/\s/g, '')]
};

export default Payments;
