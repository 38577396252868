import '../style.scss';

import React from 'react';

import { payment } from '../../../content';
import { StripeForm } from '../..';

const Details = ({ model }) => (
    <div>
        {model === 'monthly' && <div className="trial-text">{payment.details.trial}</div>}
        <StripeForm />
    </div>
);

export default Details;
