import './style.scss';

import React, { useEffect, useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

import { modal } from '../../../content';

const Select = ({ onChange, tip }) => {
    const [custom, setCustom] = useState('0');
    useEffect(() => {
        if (!modal.tip.options.includes(tip)) {
            setCustom(tip);
        }
    }, [tip]);

    const onSelect = (e) => onChange(e.target.value);

    return (
        <ButtonGroup toggle>
            {modal.tip.options.map((option) => (
                <ToggleButton
                    type="radio"
                    key={option}
                    value={option}
                    checked={option === tip}
                    onChange={onSelect}
                >
                    ${option}
                </ToggleButton>
            ))}
            <ToggleButton
                type="radio"
                key="custom"
                value={custom}
                checked={!modal.tip.options.includes(tip)}
                onChange={onSelect}
            >
                ${custom}
            </ToggleButton>
        </ButtonGroup>
    );
};

export default Select;
