import './style.scss';

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Textarea from './Textarea';

const MultiSelect = ({
    options = [],
    type,
    props: { textbox = false, textToggle = '', placeholder = '' },
    onChange
}) => {
    const [showText, setShowText] = useState(false);
    const [vertical, setVertical] = useState(false);
    const [selected, setSelected] = useState({});

    useEffect(() => {
        setVertical(options.length <= 5);
        setShowText(textToggle.length ? false : textbox);
        setSelected({});
    }, [options]);

    const onSelect = (selection) => {
        let reply = { ...selected };
        if (!reply.selected_reply_options) reply.selected_reply_options = [];
        if (selection.checked) {
            if (!reply.selected_reply_options.includes(selection.key)) {
                reply.selected_reply_options.push(selection.key);
            }
        } else {
            reply.selected_reply_options = reply.selected_reply_options.filter(
                (item) => item !== selection.key
            );
            if (selection.key === textToggle) delete reply.additional_information;
        }
        if (selection.key === textToggle) {
            if (!reply.additional_information) reply.additional_information = {};
            const attr = type === 'what_matters_to_you' ? 'comments' : 'other_text';
            reply.additional_information[attr] = selection.text ? selection.text : '';
        }
        setSelected(reply);
        onChange(reply);
    };

    return (
        <Container className={`multi-select ${vertical ? 'vertical' : ''}`}>
            <Row>
                {options.map(({ key, text }) => (
                    <Col xs={12} sm={vertical ? 12 : 6} key={key} className="quiz-checkbox">
                        <input
                            className="styled-checkbox"
                            type="checkbox"
                            id={key}
                            onChange={(e) => {
                                if (key === textToggle) {
                                    setShowText(e.target.checked);
                                }
                                onSelect({ checked: e.target.checked, key });
                            }}
                        />
                        <label className="checkbox-label" htmlFor={key}>
                            {text}
                        </label>
                    </Col>
                ))}
            </Row>
            {textbox && showText && (
                <Textarea
                    key={type}
                    onChange={({ reply }) =>
                        onSelect({ key: textToggle, checked: true, text: reply })
                    }
                    props={{ placeholder }}
                />
            )}
        </Container>
    );
};

export default MultiSelect;
