import { Cookies, Stylist, TwilioService } from '../../../services';

export const STYLIST_LOADING_START = 'STYLIST_LOADING_START';
export const STYLIST_LOAD_START = 'STYLIST_LOAD_START';
export const SET_STYLIST = 'SET_STYLIST';

export const SET_STYLIST_REVIEWS = 'SET_STYLIST_REVIEWS';

export const SET_STYLIST_LOOKS = 'SET_STYLIST_LOOKS';
export const UPDATE_STYLIST_LOOKS = 'UPDATE_STYLIST_LOOKS';
export const CLEAR_LOOKS = 'CLEAR_LOOKS';

export const LOAD_CLIENTS_SUCCESS = 'LOAD_CLIENTS_SUCCESS';

export const UPDATE_STYLIST_FAVORITES = 'UPDATE_STYLIST_FAVORITES';

export const UPDATE_LAST_MESSAGE = 'UPDATE_LAST_MESSAGE';

export const STYLIST_ACTION_FAILED = 'STYLIST_ACTION_FAILED';

export const stylistLoadingStart = () => ({
    type: STYLIST_LOADING_START
});

export const stylistLoadStart = () => ({
    type: STYLIST_LOAD_START
});

export const setStylist = (stylist) => ({
    type: SET_STYLIST,
    payload: { stylist }
});

export const setStylistReviews = (reviews) => ({
    type: SET_STYLIST_REVIEWS,
    payload: { reviews }
});

export const setStylistLooks = (looks) => ({
    type: SET_STYLIST_LOOKS,
    payload: { looks }
});

export const looksUpdate = (looks) => ({
    type: UPDATE_STYLIST_LOOKS,
    payload: { looks }
});

export const clearLooks = () => ({
    type: CLEAR_LOOKS
});

export const updateStylistFavorites = ({ items = [], total_count }) => ({
    type: UPDATE_STYLIST_FAVORITES,
    payload: { items, total_count }
});

export const updateLastMessage = (message) => ({
    type: UPDATE_LAST_MESSAGE,
    payload: { message }
});

export const stylistActionFailed = (error, type) => ({
    type: STYLIST_ACTION_FAILED,
    payload: { error: { ...error, type } }
});

export const loadStylist = (stylist_id) => {
    return async (dispatch) => {
        dispatch(stylistLoadStart());
        try {
            const user = Cookies.get('user');
            let { data } = await Stylist.get(stylist_id, user ? user.uuid : null);
            dispatch(setStylist(data));
        } catch (e) {
            dispatch(stylistActionFailed(e, 'stylist'));
            window.location.href = '/stylistsearch';
        }
    };
};

export const loadStylistReviews = (uuid, from = 0, count = 5) => {
    return async (dispatch) => {
        dispatch(stylistLoadingStart());
        try {
            const { data } = await Stylist.reviews(uuid, from, count);
            dispatch(setStylistReviews(data));
        } catch (error) {
            dispatch(stylistActionFailed(error, 'stylistsreviews'));
        }
    };
};

export const loadStylistLooks = (uuid, params = {}) => {
    return async (dispatch) => {
        dispatch(stylistLoadingStart());
        try {
            const user = Cookies.get('user');
            const isPublic = params.public !== undefined ? params.public : true;
            if (!params.from) params.from = 0;
            if (!params.count) params.count = 5;
            if (params.from === 0) dispatch(clearLooks());
            const {
                data: { outfits }
            } = await Stylist.looks(uuid, {
                ...params,
                public: isPublic,
                type: 'look',
                reader_uuid: user ? user.uuid : null
            });
            dispatch(setStylistLooks(outfits));
        } catch (error) {
            dispatch(stylistActionFailed(error, 'stylistslooks'));
        }
    };
};

export const updateStylistLook = (outfit_uuid, look) => {
    return async (dispatch, getState) => {
        const { looks } = getState().stylists;
        dispatch(looksUpdate(looks.map((item) => (item.uuid === outfit_uuid ? look : item))));
    };
};

export const endSession = (session_id) => {
    return async (dispatch) => {
        try {
            const { token } = Cookies.get('user');
            await Stylist.endSession(session_id, token);
        } catch (error) {
            dispatch(stylistActionFailed(error, 'endsession'));
        }
    };
};
export const loadClientsSuccess = (clients) => ({
    type: LOAD_CLIENTS_SUCCESS,
    payload: { clients }
});

export const loadStylistFavorites = (params = {}) => {
    return async (dispatch, getState) => {
        try {
            const user = Cookies.get('user');
            const { favorites } = getState().stylists;
            if (user) {
                if (!params.from) params.from = 0;
                if (!params.count) params.count = 100;
                const {
                    data: { items, total_count }
                } = await Stylist.favorites(user.uuid, params);
                dispatch(
                    updateStylistFavorites({
                        items: params.from === 0 ? items : [...favorites, ...items],
                        total_count
                    })
                );
            }
        } catch (error) {
            dispatch(stylistActionFailed(error, 'loadclient'));
        }
    };
};

export const favorite = (favouritesFilter) => {
    return async (dispatch, getState) => {
        try {
            const { favorites } = getState().stylists;
            const user = Cookies.get('user');

            if (!user) return;
            const itemIndex = getItemIndex(
                favorites,
                favouritesFilter.item,
                favouritesFilter.attribute
            );
            const isExist = itemIndex > -1;

            if (!isExist) {
                await Stylist.favorite(user.uuid, favouritesFilter.item);
            } else {
                const uniqueId =
                    favouritesFilter.attribute === 'uuid' ? 'item_uuid' : 'originalItemUnique';
                await Stylist.storeRemove(user.uuid, [favorites[itemIndex][uniqueId]]);
            }
            dispatch(
                loadStylistFavorites({
                    ...favouritesFilter.params,
                    from: 0,
                    gender: favouritesFilter.gender
                })
            );
        } catch (error) {
            dispatch(stylistActionFailed(error, 'favorite'));
        }
    };
};

export const contact = (stylist_uuid, message = '') => {
    return async (dispatch) => {
        try {
            const { token, uuid } = Cookies.get('user');
            const session = await Stylist.createSession({
                token,
                body: {
                    client_uuid: uuid,
                    stylist_uuid
                }
            });
            const result = await TwilioService.sendMessage({
                channel_sid: session.data.sid,
                sender: 'client',
                type: 'text',
                content: message
            });
            dispatch(updateLastMessage(result));
        } catch (error) {
            dispatch(updateLastMessage());
            dispatch(stylistActionFailed(error, 'important'));
        }
    };
};

const getItemIndex = (favorites, item, attr) => {
    return favorites.findIndex((favorite) =>
        attr === 'product_id'
            ? favorite?.sku[0] === item?.sku[0]
            : favorite.originalItemUnique === item[attr] || favorite.uuid === item[attr]
    );
};
