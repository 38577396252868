import axios from 'axios';
import Config from './Config';
import { getUserToken } from './utils/token-utils';

const Session = {
    set: (name, data) => {
        const item = Session.get(name);

        if (item) {
            return sessionStorage.setItem(name, JSON.stringify({ ...item, ...data }));
        }
        return sessionStorage.setItem(name, JSON.stringify(data));
    },
    get: (name) => JSON.parse(sessionStorage.getItem(name)) || {},
    cancel: (session_uuid) =>
        axios.put(`${Config.get('apiroot2')}session/${session_uuid}/cancel`, null, {
            headers: { token: getUserToken() }
        }),
    reactivate: (session_uuid) =>
        axios.put(`${Config.get('apiroot2')}session/${session_uuid}/reactivate`, null, {
            headers: { token: getUserToken() }
        }),
    remove: (name) => sessionStorage.removeItem(name)
};

export default Session;
