import './style.scss';

import React, { useState } from 'react';
import { ButtonProps, Accordion, Button, Card, Collapse, Image } from 'react-bootstrap';

import { logo } from 'content.json';
import { BookingState } from 'store/booking/types';
import { PaymentModel } from 'store/payments/types';
type PlanType = Pick<BookingState, 'plan'>['plan'];
interface IPlan {
    plan: PlanType;
    model: PaymentModel;
    celebrity?: boolean;
    isRecommended: boolean;
    highlighted: boolean;
    onSelect: (_plan: PlanType) => void;
    price?: number;
    campaign: any;
    button: Pick<ButtonProps, 'variant'>['variant'];
    buttonText?: string;
}
const Plan: React.FC<IPlan> = ({
    plan,
    model,
    celebrity = false,
    isRecommended,
    highlighted,
    onSelect,
    price,
    campaign,
    button,
    buttonText
}) => {
    const [collapsed, setCollapsed] = useState<number[]>([]);
    const setCollapsedItems = (key: number) => {
        if (collapsed.includes(key)) {
            setCollapsed(collapsed.filter((item) => item !== key));
        } else {
            setCollapsed([...collapsed, key]);
        }
    };

    return (
        <Card
            className={`plan ${
                highlighted
                    ? plan?.value === 'lux'
                        ? 'selected-lux'
                        : 'selected'
                    : plan?.value === 'lux'
                    ? 'selected-lux'
                    : ''
            }`}
        >
            {isRecommended && <div className="ribbon">{plan?.ribbon}</div>}
            <Card.Header>
                <Image src={logo.text_black} /> {plan?.title}
            </Card.Header>
            <Card.Body>
                <div className="price">{price || plan?.price[model]}</div>
                {(!campaign || (campaign && campaign.plans.description)) && (
                    <p className="description d-none d-md-flex">{plan?.description}</p>
                )}
                <Button variant={button ?? 'dark'} onClick={() => onSelect(plan)}>
                    {buttonText || plan?.button}
                </Button>

                <Accordion className="d-none d-sm-block">
                    {plan?.details?.map(
                        ({ title, description, showCelebrity }, index) =>
                            (!celebrity || (celebrity && showCelebrity)) && (
                                <div
                                    key={title}
                                    className={`plan-item ${
                                        collapsed.includes(index) ? 'open' : ''
                                    }`}
                                    onClick={() => setCollapsedItems(index)}
                                >
                                    <div
                                        className="item-title"
                                        dangerouslySetInnerHTML={{ __html: title }}
                                    />
                                    <Collapse in={collapsed.includes(index)}>
                                        <p>{description}</p>
                                    </Collapse>
                                </div>
                            )
                    )}
                </Accordion>

                <ul className="d-block d-sm-none">
                    {plan?.details?.map(({ title, showMobile }) =>
                        showMobile ? (
                            <li key={title} dangerouslySetInnerHTML={{ __html: title }} />
                        ) : (
                            ''
                        )
                    )}
                </ul>
            </Card.Body>
        </Card>
    );
};

export default Plan;
