import './style.scss';

import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';

import { quiz } from '../../content';

const Selectors = ({ options, onChange, mandatory = false, props = {} }) => {
    const [list, setList] = useState([]);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        let arr = options.filter((option) => option.key.includes('_US'));
        if (arr) {
            if (props.order) {
                let sorted = [...props.order];
                sorted.map((item) => {
                    const size = arr.find((element) => element.key === item);
                    if (size) {
                        sorted[sorted.indexOf(item)] = size;
                    }
                });
                sorted = sorted.filter((item) => typeof item !== 'string');
                setList(sorted);
            } else {
                setList(arr);
            }
        }
    }, [options]);

    const onSelect = (key, option) => {
        let arr = [...selected];
        if (arr.find((item) => item.key === key)) {
            arr = arr.filter((item) => item.key !== key);
        }
        arr.push({ key, selected_reply_options: [option] });
        setSelected(arr);
        if (!mandatory || (mandatory && arr.length === list.length)) {
            onChange({ selected_reply_options: arr });
        }
    };

    return (
        <Container className="selectors">
            {list.map((option) => (
                <Row key={option.key}>
                    <Col xs={12}>
                        <Form.Label>{option.text}</Form.Label>
                    </Col>
                    <Col xs={12}>
                        <Form.Control
                            as="select"
                            id={`selector_${option.key}`}
                            onChange={(e) => onSelect(option.key, e.target.value)}
                        >
                            <option value="" disabled selected>
                                {quiz.questions.button[props.placeholder]}
                            </option>
                            {option.reply_options.map((reply) => (
                                <option key={reply.key} value={reply.key}>
                                    {reply.text}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                </Row>
            ))}
        </Container>
    );
};

export default Selectors;
