import { connect } from 'react-redux';
import { changeModal } from 'store/ui/actions';

import { loadStylistReviews } from '../../../redux/reducers/stylists/actions';
import AddReview from './AddReview';

const mapStateToProps = ({ user: { user }, stylists: { stylist } }) => ({
    user,
    stylist
});

const mapDispatchToProps = (dispatch) => ({
    changeModal: (type, data) => changeModal(dispatch, { type, data }),
    loadStylistReviews: (uuid) => dispatch(loadStylistReviews(uuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddReview);
