import './style.scss';

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { quiz } from '../../content';
import Textarea from './Textarea';

const SingleSelect = ({
    options,
    onChange,
    type,
    props: { textbox = false, textToggle = '', placeholder = '', skippable = false }
}) => {
    const [showText, setShowText] = useState(false);
    const [text, setText] = useState(false);
    const [selected, setSelected] = useState({});

    useEffect(() => {
        setSelected({});
        setShowText(false);
    }, [options]);

    const onSelect = (selection) => {
        let reply = { ...selected };
        reply.selected_reply_options = [];
        if (selection.other_text !== undefined) {
            reply.selected_reply_options = [textToggle];
            reply.additional_information = {
                other_text: selection.other_text.length ? selection.other_text : ''
            };
        } else if (selection.length) {
            reply.selected_reply_options = [selection];
        }
        setSelected(reply);
        setTimeout(() => onChange(reply), 500);
    };

    return (
        <Container className="single-select">
            <Row>
                {options.map(({ key, text }) => (
                    <Col
                        xs={12}
                        id={`button_${key}`}
                        key={key}
                        className={`quiz-button ${
                            selected.selected_reply_options &&
                            selected.selected_reply_options[0] === key
                                ? 'selected'
                                : ''
                        }`}
                    >
                        <Button
                            variant="outline-dark"
                            onClick={() => (key === textToggle ? setShowText(true) : onSelect(key))}
                        >
                            {text}
                        </Button>
                    </Col>
                ))}
                {skippable && (
                    <Col xs={12} id="button_skip" key="button_skip" className="quiz-button">
                        <Button variant="outline-dark" onClick={() => onSelect({})}>
                            {quiz.questions.button.skip}
                        </Button>
                    </Col>
                )}
            </Row>
            {textbox && showText && (
                <Row>
                    <Col className="no-padding">
                        <Textarea
                            key={type}
                            onChange={({ reply }) => setText(reply)}
                            props={{ placeholder }}
                        />
                        <div className="done-btn" onClick={() => onSelect({ other_text: text })}>
                            {quiz.questions.button.done}
                        </div>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default SingleSelect;
