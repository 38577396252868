import { connect } from 'react-redux';
import { updateUser } from 'store/user/actions';

import {
    nextStep,
    nextStyle,
    selectBodyType,
    selectGender,
    selectStyle,
    setBodyTypeList,
    setStylesList
} from '../../../redux/reducers/onboarding/actions';
import BodyType from './BodyType';
import Gender from './Gender';
import Style from './Style';

const mapStateToProps = ({
    onboarding: { steps, gender, bodyTypeList, bodyTypes, stylesList, styles, styleIndex }
}) => ({
    steps,
    gender,
    bodyTypeList,
    bodyTypes: bodyTypes.reduce((ac, a) => ({ ...ac, [a.tag_uuid]: a.is_set }), {}),
    bodyTypesNames: bodyTypes
        .reduce((ac, a) => {
            ac.push(a.name.toLowerCase());
            return ac;
        }, [])
        .toString(),
    stylesList,
    styles,
    styleIndex
});

const mapDispatchToProps = (dispatch) => ({
    nextStep: () => dispatch(nextStep()),
    selectGender: (gender) => dispatch(selectGender(gender)),
    setStylesList: (styles) => dispatch(setStylesList(styles)),
    selectStyle: (style, answer, index) => dispatch(selectStyle(style, answer, index)),
    nextStyle: (styleIndex) => dispatch(nextStyle(styleIndex)),
    setBodyTypeList: (bodyTypes) => dispatch(setBodyTypeList(bodyTypes)),
    selectBodyType: (bodyType) => dispatch(selectBodyType(bodyType)),
    updateUser: (data) => updateUser(dispatch, data)
});

const components = {
    Gender: connect(mapStateToProps, mapDispatchToProps)(Gender),
    BodyType: connect(mapStateToProps, mapDispatchToProps)(BodyType),
    Style: connect(mapStateToProps, mapDispatchToProps)(Style)
};

export default components;
