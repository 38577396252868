import { Stylist } from 'types/user';
import { IActionWithPayload, IAction } from '../types';
import campaigns from 'campaigns.json';
import { pricing } from 'content.json';
import { Booking } from 'types/booking';

export enum ActionTypes {
    BOOK_STYLIST = 'booking/BOOK_STYLIST',
    SELECT_GOAL = 'booking/SELECT_GOAL',
    CLEAR_BOOKINGS = 'booking/CLEAR_BOOKINGS',
    SELECT_PLAN = 'booking/SELECT_PLAN',
    CAMPAIGN_BOOKING = 'booking/CAMPAIGN_BOOKING',
    CLEAR_BOOKINGS_LIST = 'booking/CLEAR_BOOKINGS_LIST',
    LOAD_BOOKINGS = 'booking/LOAD_BOOKINGS',
    LOAD_BOOKINGS_REQUEST = 'booking/LOAD_BOOKINGS_REQUEST',
    LOAD_BOOKINGS_SUCCESS = 'booking/LOAD_BOOKINGS_SUCCESS',
    LOAD_CLIENTS_FOR_STYLIST = 'booking/LOAD_CLIENTS_FOR_STYLIST',
    LOAD_CLIENTS_FOR_STYLIST_REQUEST = 'booking/LOAD_CLIENTS_FOR_STYLIST_REQUEST',
    LOAD_CLIENTS_FOR_STYLIST_SUCCESS = 'booking/LOAD_CLIENTS_FOR_STYLIST_SUCCESS',
    SET_FILTER = 'booking/SET_FILTER',
    SET_TOTAL_BOOKINGS = 'booking/SET_TOTAL_BOOKINGS',
    UPDATE_FOLLOW_UP_CHAT = 'booking/UPDATE_FOLLOW_UP_CHAT',
    SET_BOOKINGS_STATUSES = 'booking/SET_BOOKINGS_STATUSES',
    SET_BOOKINGS = 'booking/SET_BOOKINGS'
}
export type Plan = typeof pricing.plans[number];
export interface Filter {
    search: string;
    status: {
        key: string;
        value?: string;
        count?: number;
    };
}
export interface StatusOption {
    key: string;
    value: string;
    count: number;
}
export interface BookingState {
    loading: boolean;
    goal: any | null;
    plan: Plan | null;
    stylist: Stylist | null;
    campaign: keyof typeof campaigns | null;
    bookings: Booking[];
    filter: Filter;
    totalBookings: number;
    bookingStatuses: StatusOption[];
    clientsForStylist: any[];
}

export type Actions =
    | IActionWithPayload<ActionTypes.SELECT_PLAN, Plan | null>
    | IActionWithPayload<ActionTypes.BOOK_STYLIST, Stylist>
    | IActionWithPayload<ActionTypes.SELECT_GOAL, any>
    | IAction<ActionTypes.CLEAR_BOOKINGS>
    | IAction<ActionTypes.CLEAR_BOOKINGS_LIST>
    | IActionWithPayload<ActionTypes.CAMPAIGN_BOOKING, any>
    | IActionWithPayload<ActionTypes.LOAD_BOOKINGS_SUCCESS, Booking[]>
    | IActionWithPayload<ActionTypes.SET_FILTER, Filter>
    | IActionWithPayload<ActionTypes.SET_TOTAL_BOOKINGS, number>
    | IActionWithPayload<
          ActionTypes.UPDATE_FOLLOW_UP_CHAT,
          { session_id: string; enabled: boolean }
      >
    | IActionWithPayload<ActionTypes.SET_BOOKINGS_STATUSES, StatusOption[]>
    | IActionWithPayload<ActionTypes.LOAD_CLIENTS_FOR_STYLIST_SUCCESS, any[]>
    | IActionWithPayload<ActionTypes.SET_BOOKINGS, Booking[]>;
