/* eslint-disable no-useless-escape */

import isEmail from 'validator/lib/isEmail';

import { validations } from '../content';

const Validator = {
    name: (name, fullname = true) => {
        if (!/^\w{2,}(\s[\w\s]{2,})?$/.test(name)) {
            return { name: validations.invalid };
        }
        /*let errors = null;
        const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        const isShortName = /\w{2,} \w{2,}/;
        if (!name) return { name: validations.name };

        //minimum 2 character at first and last name with no space in the start
        if (!isShortName.test(name) || /^\s/.test(name)) {
            errors = { ...errors, name: validations.invalid };
        }

        if (hasSymbol.test(name)) {
            errors = { ...errors, name: validations.characters };
        }
        if (/\d/.test(name)) {
            errors = { ...errors, name: validations.invalid };
        }

        if (fullname && !isShortName.test(name)) {
            errors = { ...errors, lastname: validations.lastname };
        }
        return errors;*/
    },
    number: (number) => {
        let errors = null;
        if (isNaN(number)) {
            errors = { ...errors, number: validations.number };
        }
        return errors;
    },
    email: (email) => {
        let errors = null;

        const hasSymbol = /[!@#$%^&*()\=\[\]{};':"\\|,<>\/?]/;
        if (!email) return { email: validations.emailRequired };

        const emailUsername = email.split('@')[0];
        if (hasSymbol.test(emailUsername)) return { email: validations.characters };

        if (!isEmail(email)) return { email: validations.email };

        return errors;
    },
    password: (password) => {
        let errors = null;
        if (!password) {
            errors = { ...errors, password: validations.password };
        }
        if (password && password.length < 6) {
            errors = { ...errors, password: validations.passwordShort };
        }
        return errors;
    }
};

export default Validator;
