import './style.scss';

import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { isExternalItem, isShopableItem } from 'services/utils/item-utils';
import { ItemType } from 'types/item';

import { item as texts } from '../../content.json';
import { Formatter } from '../../services';

interface IMoreItems {
    items: ItemType[];
    onClick: (item: ItemType) => void;
    onCart: (item: ItemType) => void;
}

const MoreItems: React.FC<IMoreItems> = ({ items, onClick, onCart }) => {
    const onItemClick = (item: ItemType) => {
        if (isExternalItem(item)) {
            window.open(item.buy_url, '_blank');
        }

        if (item.unique && isShopableItem(item)) {
            onClick(item);
        }
    };

    return (
        <Row className="more-items">
            <Col>
                <div className="title">{texts.more}</div>
                <div className="items">
                    {items.map((item) => (
                        <div key={item.uuid || item.unique} className="item">
                            {isShopableItem(item) && !item?.is_in_closet && (
                                <div
                                    className={`cart-icon ${item.is_in_cart ? 'added' : 'add'}`}
                                    onClick={() => onCart(item)}
                                />
                            )}
                            <span onClick={() => onItemClick(item)}>
                                <Image src={item.picture} />
                                <p className="brand">{item.brand_name}</p>
                                <p className="price">
                                    {item.price && parseInt(item.price) > 0
                                        ? Formatter.price(item.price)
                                        : ''}
                                </p>
                                {isExternalItem(item) && (
                                    <div className="shop-externally">
                                        Shop at {item.retailer_name}
                                    </div>
                                )}
                            </span>
                            {isShopableItem(item) && (
                                <p
                                    className={`add-cart d-block d-sm-none ${
                                        item.is_in_cart ? 'added' : 'add'
                                    }`}
                                    onClick={() => onCart(item)}>
                                    {item.is_in_cart ? texts.cartAdded : texts.cartAdd}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            </Col>
        </Row>
    );
};

export default MoreItems;
