import Input from '../Input';
import Birthday from './Birthday';
import Budget from './Budget';
import Grid from './Grid';
import Moodboards from './Moodboards';
import MultiSelect from './MultiSelect';
import Selectors from './Selectors';
import SingleSelect from './SingleSelect';
import Textarea from './Textarea';
import Upload from './Upload';

export default {
    MultiSelect,
    SingleSelect,
    Birthday,
    Textarea,
    Grid,
    Selectors,
    Budget,
    Upload,
    Input,
    Moodboards
};
