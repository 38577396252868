import './style.scss';

import React from 'react';
import { ItemType } from 'types/item';
import { item as texts } from '../../content.json';
import { Formatter } from '../../services';

interface IItemDetails {
    item?: ItemType;
}

const ItemDetails: React.FC<IItemDetails> = ({ item }) => (
    <div className="details">
        <div className="brand">{item?.brand_name}</div>
        <div className="name">{item?.name}</div>
        <div className="prices">
            {item?.retail_price && parseInt(item.retail_price) > 0 && (
                <p className={`price ${item?.sale_price ? ' line-through' : ''}`}>
                    {Formatter.price(item.retail_price)}
                </p>
            )}
            {item?.sale_price && parseInt(item.sale_price) < parseInt(item.retail_price) && (
                <>
                    <p className="price">{Formatter.price(item.sale_price)}</p>
                    <p className="price discount">
                        {texts.payment.discount.replace(
                            '%price%',
                            Formatter.price(parseInt(item.retail_price) - parseInt(item.sale_price))
                        )}
                    </p>
                </>
            )}
        </div>
    </div>
);

export default ItemDetails;
