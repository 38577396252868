import { Cookies, Style, Stylist } from '../../../services';
import { pageLoaded } from '../users/actions';

export const LOAD_START = 'LOAD_START';
export const LOAD_FAILED = 'LOAD_FAILED';

export const LOAD_STYLISTS_SUCCESS = 'LOAD_STYLISTS_SUCCESS';

export const LOAD_FILTER_LIST_SUCCESS = 'LOAD_FILTER_LIST_SUCCESS';

export const TOGGLE_FILTERS = 'TOGGLE_FILTERS';
export const UPDATE_STYLE_SELECTED_FILTERS = 'UPDATE_STYLE_SELECTED_FILTERS';
export const SET_FILTER_RESULTS_COUNT = 'SET_FILTER_RESULTS_COUNT';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export const UPDATE_FEED = 'UPDATE_FEED';
export const CLEAR_FEED = 'CLEAR_FEED';
export const UPDATE_FEED_GENDER = 'UPDATE_FEED_GENDER';

export const LOAD_OUTFIT_START = 'LOAD_OUTFIT_START';
export const UPDATE_OUTFIT = 'UPDATE_OUTFIT';
export const CLEAR_OUTFIT = 'CLEAR_OUTFIT';

export const LOAD_ITEM_START = 'LOAD_ITEM_START';
export const UPDATE_ITEM = 'UPDATE_ITEM';

export const LOAD_CAMPAIGN_FILTERS_SUCCESS = 'LOAD_CAMPAIGN_FILTERS_SUCCESS';

export const UPDATE_CAMPAIGN_ITEMS = 'UPDATE_CAMPAIGN_ITEMS';
export const CLEAR_CAMPAIGN_ITEMS = 'CLEAR_CAMPAIGN_ITEMS';

export const UPDATE_TAGS = 'UPDATE_TAGS';
export const UPDATE_EVENTS = 'UPDATE_EVENTS';

export const loadStart = () => ({
    type: LOAD_START
});

export const loadFailed = (error) => ({
    type: LOAD_FAILED,
    payload: { error }
});

export const loadFilterListSuccess = (filters) => ({
    type: LOAD_FILTER_LIST_SUCCESS,
    payload: { filters }
});

export const updateFilters = (selectedFilters) => ({
    type: UPDATE_STYLE_SELECTED_FILTERS,
    payload: { selectedFilters }
});

export const clearFilters = () => ({
    type: CLEAR_FILTERS
});

export const toggleFilters = () => ({
    type: TOGGLE_FILTERS
});

export const updateFeed = ({ feed, quota_max }) => ({
    type: UPDATE_FEED,
    payload: { feed, quota_max }
});

export const clearFeed = () => ({
    type: CLEAR_FEED
});

export const loadOutfitStart = () => ({
    type: LOAD_OUTFIT_START
});

export const updateOutfit = (outfit) => ({
    type: UPDATE_OUTFIT,
    payload: { outfit }
});

export const clearOutfit = () => ({
    type: CLEAR_OUTFIT
});

export const loadItemStart = () => ({
    type: LOAD_ITEM_START
});

export const updateItem = (item = {}) => ({
    type: UPDATE_ITEM,
    payload: { item }
});

export const updateFeedGender = (feedGender) => ({
    type: UPDATE_FEED_GENDER,
    payload: { feedGender }
});

export const loadCampaignFilterSuccess = (filters = []) => ({
    type: LOAD_CAMPAIGN_FILTERS_SUCCESS,
    payload: { filters }
});

export const updateCampaignItems = (items, nextPage) => ({
    type: UPDATE_CAMPAIGN_ITEMS,
    payload: { items, nextPage }
});

// TODO: move this one to new code...
export const setFilter = (update) => {
    return async (dispatch, getState) => {
        const {
            style: { selectedFilters }
        } = getState();
        let _filters = { ...selectedFilters };

        if (!_filters[update.key]) {
            _filters[update.key] = [];
        }
        if (_filters[update.key].find((item) => item.key === update.value.key)) {
            _filters[update.key] = _filters[update.key].filter(
                (filter) => filter.key !== update.value.key
            );
        } else {
            _filters[update.key].push(update.value);
        }
        if (!_filters[update.key].length) delete _filters[update.key];

        dispatch(updateFilters(_filters));
    };
};

export const loadFeed = (params = { from: 0, count: 30 }) => {
    return async (dispatch, getState) => {
        dispatch(clearFilters());
        dispatch(loadStart());
        try {
            const {
                style: { feed, selectedFilters }
            } = getState();
            if (params.from === 0) dispatch(clearFeed());

            const {
                data: { items, quota_max }
            } = await Style.getFeed({ ...selectedFilters, ...params });
            dispatch(
                updateFeed({ feed: params.from === 0 ? items : [...feed, ...items], quota_max })
            );
            dispatch(pageLoaded());
        } catch (error) {
            dispatch(loadFailed(error));
        }
    };
};

export const loadOutfit = (outfit_uuid) => {
    return async (dispatch) => {
        dispatch(clearFilters());
        dispatch(loadOutfitStart());
        try {
            let uuid = null;
            const cookie = Cookies.get('user');
            if (cookie) {
                uuid = Cookies.get('user').uuid;
            }
            const { data } = await Style.getOutfit(outfit_uuid, uuid);
            dispatch(updateOutfit(data));
        } catch (error) {
            dispatch(loadFailed(error));
        }
    };
};

export const loadItem = (item_uuid) => {
    return async (dispatch) => {
        dispatch(loadItemStart());
        try {
            let user_uuid = null;
            const cookie = Cookies.get('user');
            if (cookie) {
                user_uuid = Cookies.get('user').uuid;
            }
            const { data } = await Style.getItem(item_uuid, user_uuid);
            dispatch(updateItem(data));
        } catch (error) {
            dispatch(loadFailed(error));
        }
    };
};

export const loadFeedFilters = (user_uuid) => {
    return async (dispatch) => {
        try {
            const { data } = await Stylist.filters(user_uuid);
            dispatch(loadFilterListSuccess(data));
        } catch (error) {
            dispatch(loadFailed(error));
        }
    };
};

export const loadCampaignFilters = () => {
    return async (dispatch) => {
        dispatch(loadStart());
        try {
            const { data } = await Style.filters();
            dispatch(
                loadCampaignFilterSuccess(
                    data.reduce(
                        (ac, { key, value, filter_options }) => ({
                            ...ac,
                            [key]: { value, filter_options }
                        }),
                        {}
                    )
                )
            );
        } catch (error) {
            dispatch(loadFailed(error));
        }
    };
};

export const loadCampaignItems = (params) => {
    return async (dispatch, getState) => {
        dispatch(loadStart());
        try {
            const ITEMS_COUNT = 30;
            const cookie = Cookies.get('user');
            const {
                style: { campaignItems }
            } = getState();
            if (cookie) {
                params.reader_uuid = Cookies.get('user').uuid;
            }
            if (!params.from) params.from = 1;
            if (!params.count) params.count = ITEMS_COUNT;
            if (!params.order) params.order = 'asc';
            const { data } = await Style.giftItems(params);
            dispatch(
                updateCampaignItems(
                    params.from === 1 ? data.items : [...campaignItems, ...data.items],
                    data.nextPage
                )
            );
        } catch (error) {
            dispatch(loadFailed(error));
        }
    };
};
