import { IActionWithPayload, IAction } from 'store/types';
import { Stylist } from 'types/user';
import { inbox } from 'content.json';

export enum ActionTypes {
    LOAD_INBOX_MESSAGES = 'inbox/LOAD_INBOX_MESSAGES',
    LOAD_INBOX_MESSAGES_REQUEST = 'inbox/LOAD_INBOX_MESSAGES_REQUEST',
    LOAD_INBOX_MESSAGES_SUCCESS = 'inbox/LOAD_INBOX_MESSAGES_SUCCESS',
    LOAD_INBOX_MESSAGES_FAILURE = 'inbox/LOAD_INBOX_MESSAGES_FAILURE',
    SELECT_CHANNEL = 'inbox/SELECT_CHANNEL',
    CLEAR_INBOX_MESSAGES = 'inbox/CLEAR_INBOX_MESSAGES'
}
export interface Message {
    sid: string;
    plan: keyof typeof inbox.plans;
    current_session_status: string;
    last_message: {
        created: string;
        sender: string;
        type: string;
        text: string;
    };
    type: string;
    text: string;
    subscription_id?: string;
    isSubscription: boolean;
    stylist: Stylist;
}
export interface InboxState {
    indicators: {
        loading: boolean;
        error: any | null;
    };
    messages: Message[];
    channel: string;
}

export type Actions =
    | IAction<ActionTypes.LOAD_INBOX_MESSAGES_REQUEST>
    | IAction<ActionTypes.LOAD_INBOX_MESSAGES_FAILURE>
    | IActionWithPayload<ActionTypes.LOAD_INBOX_MESSAGES_SUCCESS, Message[]>
    | IActionWithPayload<ActionTypes.SELECT_CHANNEL, string>
    | IAction<ActionTypes.CLEAR_INBOX_MESSAGES>;
