import React, { useEffect } from 'react';
import './style.scss';
import { Container } from 'react-bootstrap';
import { Page } from '../../components';
import { howItWorks } from '../../content.json';
import { PageHeader, Steps, LetGetStyling, Reviews } from './sections';
import { trackZEWidgetClick } from '../../services/utils/zenDesk-widget';
import { MP_EVENTS } from 'services/mixpanel';

const HowItWorks: React.FC = () => {
    useEffect(() => {
        trackZEWidgetClick(MP_EVENTS.HOW_IT_WORKS_HELP_WIDGET);
    }, []);
    return (
        <Page>
            <Container className="how-it-works">
                <PageHeader data={{ ...howItWorks.header }} />
                <Steps data={{ ...howItWorks.steps }} />
                <LetGetStyling data={{ ...howItWorks.styling }} />
            </Container>
            <div className="how-it-works warpper">
                <Reviews data={{ ...howItWorks.reviewsSection }} />
            </div>
        </Page>
    );
};

export default HowItWorks;
