import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleModal } from 'store/ui/actions';

import { toggleMobileCollapse } from '../../../redux/reducers/payments/actions';
import {
    loadCampaignFilters,
    loadCampaignItems,
    updateItem
} from '../../../redux/reducers/style/actions';
import { addToCart, removeFromCart } from '../../../redux/reducers/users/actions';
import _MobileFilters from './MobileFilters';
import Valentine from './Valentine';

const mapStateToProps = ({
    user: { user },
    payments: { mobileCollapse },
    style: { loading, campaignFilters, campaignItems }
}) => ({ loading, user, campaignFilters, campaignItems, mobileCollapse });

const mapDispatchToProps = (dispatch) => ({
    loadCampaignItems: (filters) => dispatch(loadCampaignItems(filters)),
    loadCampaignFilters: () => dispatch(loadCampaignFilters()),
    toggleMobileCollapse: (content) => dispatch(toggleMobileCollapse(content)),
    updateItem: (item) => dispatch(updateItem(item)),
    addToCart: (item, source) => dispatch(addToCart(item, source)),
    removeFromCart: (item) => dispatch(removeFromCart(item)),
    toggleModal: (data) => toggleModal(dispatch, data)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Valentine));
export const MobileFilters = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(_MobileFilters)
);
