import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleModal } from 'store/ui/actions';

import { toggleMobileCollapse } from '../../../redux/reducers/payments/actions';
import {
    loadCampaignFilters,
    loadCampaignItems,
    updateItem
} from '../../../redux/reducers/style/actions';
import { addToCart, removeFromCart } from '../../../redux/reducers/users/actions';
import Gifts from './Gifts';
import _Landing from './Landing';
import _MobileFilters from './MobileFilters';
import _Shopping from './Shopping';

const mapStateToProps = ({
    user: { user },
    payments: { mobileCollapse },
    style: { loading, campaignFilters, campaignItems, nextPage }
}) => ({ loading, user, campaignFilters, campaignItems, mobileCollapse, nextPage });

const mapDispatchToProps = (dispatch) => ({
    loadCampaignItems: (filters) => dispatch(loadCampaignItems(filters)),
    loadCampaignFilters: () => dispatch(loadCampaignFilters()),
    toggleMobileCollapse: (content) => dispatch(toggleMobileCollapse(content)),
    updateItem: (item) => dispatch(updateItem(item)),
    addToCart: (item, source) => dispatch(addToCart(item, source)),
    removeFromCart: (item) => dispatch(removeFromCart(item)),
    toggleModal: (data) => toggleModal(dispatch, data)
});

export default Gifts;
export const Landing = withRouter(connect(mapStateToProps, mapDispatchToProps)(_Landing));
export const Shopping = withRouter(connect(mapStateToProps, mapDispatchToProps)(_Shopping));
export const MobileFilters = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(_MobileFilters)
);
