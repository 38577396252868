import React from 'react';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchBox } from 'components';
import { match } from 'content.json';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useUserStore } from 'store/user/reducer';
import { Option } from 'types/utils';
import { capitalize } from 'services/utils/string-utils';

interface IMatchHeader {
    stylists: Option[];
    matchesLength?: number;
    favoriteStylistsLength?: number;
    favoriteLooksLength?: number;
    onSelect: (stylist: any) => void;
}
const MatchHeader: React.FC<IMatchHeader> = ({
    stylists,
    matchesLength = 0,
    favoriteStylistsLength = 0,
    favoriteLooksLength = 0,
    onSelect
}) => {
    const user = useUserStore((store) => store.user);

    const generateMatchesTitle = () => {
        return (
            user?.first_name &&
            match.meet
                .replace('%user%', capitalize(user.first_name))
                .replace('%numberOfMatches%', `${matchesLength}`)
        );
    };

    return (
        <>
            <Row className="header">
                <Col xs={{ span: 6, order: 1 }} md={{ span: 4, order: 1 }}>
                    {(favoriteLooksLength > 0 || favoriteStylistsLength > 0) && (
                        <Link to="/favorites">
                            <FontAwesomeIcon icon={faHeart} />
                        </Link>
                    )}
                </Col>
                <Col
                    xs={{ span: 12, order: 3 }}
                    md={{ span: 4, order: 2 }}
                    className={`title ${matchesLength > 3 ? 'results' : ''}`}
                >
                    {match.title}
                    {user && <div className="meet d-block">{generateMatchesTitle()}</div>}
                </Col>
                <Col
                    xs={{ span: 6, order: 2 }}
                    md={{ span: 4, order: 3 }}
                    className="stylists-search-box"
                >
                    <SearchBox
                        options={stylists}
                        placeholder={match.search}
                        onChange={onSelect}
                        onSubmit={() => {}}
                    />
                </Col>
            </Row>
        </>
    );
};

export default MatchHeader;
