import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useInboxStore } from 'routes/Inbox/store/reducer';
import { MP_EVENTS, trackEvent } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';
import { getUserToken } from 'services/utils/token-utils';
import { useCommonStore } from 'store/common/reducer';

import { ProfilePhoto } from '../../../components';
import { stylist as texts } from '../../../content';
import { useWithDispatch } from '../../../hooks';
import { Cookies, Formatter, Stylist, Tracking, User } from '../../../services';
import { showToast } from '../../../store/ui/actions';

const BookBar = ({ stylist, user, toggleModal, bookStylist, location }) => {
    const history = useHistory();
    const isActiveStylist = stylist.is_approved === '1' && stylist.is_active;
    const showBookStylistButton = isActiveStylist;
    const showContactStylistButton = !isActiveStylist;
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const bookingSource = useCommonStore((store) => store.bookingSource);
    const [waitListStatus, setWaitListStatus] = useState(undefined);
    const [waitListActionLoading, setWaitListActionLoading] = useState(false);
    const toast = useWithDispatch(showToast);

    const messagesByStylistId = useInboxStore((store) =>
        store.messages.reduce((obj, message) => ({ ...obj, [message.stylist.uuid]: message }), {})
    );

    const hasActiveSubscription =
        stylist.uuid in messagesByStylistId && messagesByStylistId[stylist.uuid].isSubscription;

    const onButtonClick = async () => {
        if (user) {
            if (stylist.has_active_session) {
                history.push(`/chat/${stylist.uuid}/${user.user_uuid}`);
            } else {
                bookStylist(stylist);

                try {
                    Tracking.facebook('trackCustom', 'selectStylist', {
                        id: stylist.uuid,
                        content_ids: [stylist.uuid],
                        content_type: 'product'
                    });
                    Tracking.google({
                        type: 'event',
                        event: 'Funnel',
                        data: { event_category: 'selectStylist' }
                    });
                    Tracking.google({
                        type: 'event',
                        event: 'conversion',
                        data: { send_to: 'AW-870964131/1rVICOWwnKcBEKO3p58D' }
                    });
                    Tracking.tag({ event: 'ws.selectStylist' });

                    await Tracking.report(
                        {
                            event_type: 'clicked_book_CTA',
                            user_uuid: user.user_uuid,
                            stylist_uuid: stylist.uuid
                        },
                        getUserToken()
                    );

                    Cookies.set('stylistInfo', {
                        book_stylist_out_of_profile: true,
                        first_name: stylist.first_name,
                        last_name: stylist.last_name,
                        picture_small: stylist.picture_small,
                        price: stylist.price,
                        price_subscription: stylist.price_subscription,
                        user_uuid: stylist.user_uuid ? stylist.user_uuid : stylist.uuid,
                        reviews_count: stylist.reviews_count,
                        instagram_followers: stylist.instagram_followers,
                        instagram_photos: stylist.instagram_photos,
                        clients: stylist.clients,
                        style_tags: stylist.style_tags,
                        top_outfits_count: stylist.top_outfits_count,
                        city: stylist.city,
                        average_response_time: stylist.average_response_time
                    });
                    trackEvent({
                        name: MP_EVENTS.BOOK_STYLIST,
                        properties: {
                            ...mixpanelStore.onboarding,
                            ...bookingSourceToProps(bookingSource)
                        }
                    });
                } catch (e) {
                    sentryException(e, "Couldn't process tracking request");
                }

                history.push('/goals');
            }
        } else {
            bookStylist(stylist);
            Cookies.set('stylistInfo', {
                book_stylist_out_of_profile: true,
                first_name: stylist.first_name,
                last_name: stylist.last_name,
                picture_small: stylist.picture_small,
                price: stylist.price,
                price_subscription: stylist.price_subscription,
                user_uuid: stylist.user_uuid ? stylist.user_uuid : stylist.uuid,
                reviews_count: stylist.reviews_count,
                instagram_followers: stylist.instagram_followers,
                instagram_photos: stylist.instagram_photos,
                clients: stylist.clients,
                style_tags: stylist.style_tags,
                top_outfits_count: stylist.top_outfits_count,
                city: stylist.city,
                average_response_time: stylist.average_response_time
            });

            trackEvent({
                name: MP_EVENTS.BOOK_STYLIST,
                properties: {
                    ...mixpanelStore.onboarding,
                    ...bookingSourceToProps(bookingSource)
                }
            });
            toggleModal({
                type:
                    Formatter.queryString(location.search).user_rmk === 'true'
                        ? 'Signin'
                        : 'Signup',
                url: '/goals',
                data: { source: 'book stylist click' }
            });
        }
    };

    const onWaitListButtonClick = async (joinWaitList) => {
        if (!user) {
            toggleModal({
                type: 'Signup',
                data: {
                    source: 'add to waitlist click',
                    onSuccess: () => onWaitListButtonClick(true)
                }
            });
        } else {
            setWaitListActionLoading(true);

            if (joinWaitList) {
                trackEvent({
                    name: MP_EVENTS.JOIN_WAITLIST_CLICK,
                    properties: {
                        ...mixpanelStore.onboarding,
                        ...bookingSourceToProps(bookingSource)
                    }
                });

                await Stylist.joinWaitList({ stylistId: stylist.uuid, userId: user.uuid });

                toast({
                    content: texts.waitListJoinMessage.replace('%name%', stylist.first_name),
                    onUndo: () => onWaitListButtonClick(false)
                });
            } else {
                trackEvent({
                    name: MP_EVENTS.LEAVE_WAITLIST_CLICK,
                    properties: {
                        ...mixpanelStore.onboarding,
                        ...bookingSourceToProps(bookingSource)
                    }
                });

                await User.leaveStylistWaitList({ userId: user.uuid, stylistId: stylist.uuid });

                toast({
                    content: texts.waitListLeaveMessage.replace('%name%', stylist.first_name)
                });
            }

            setWaitListStatus(joinWaitList);
            setWaitListActionLoading(false);
        }
    };

    if (showContactStylistButton && waitListStatus === undefined && !waitListActionLoading) {
        if (user) {
            setWaitListActionLoading(true);

            User.getStylistWaitListStatus({ userId: user.uuid })
                .then(({ data = [] }) => {
                    setWaitListStatus(data.includes(stylist.uuid));
                    setWaitListActionLoading(false);
                })
                .catch(() => {
                    setWaitListStatus(false);
                    setWaitListActionLoading(false);
                });
        } else {
            setWaitListStatus(false);
        }
    }

    return (
        <Navbar className="book-bar" fixed="bottom" expand={false}>
            <Container className="no-padding">
                <Nav className="d-none d-md-flex">
                    <Container>
                        <Row>
                            <Col className="profile-image">
                                <ProfilePhoto user={stylist} />
                            </Col>
                            {stylist && (
                                <Col className="stylist-details">
                                    <div className="name">{stylist.name}</div>
                                    <div className="response-time">
                                        {' '}
                                        {texts.response.replace(
                                            '%time%',
                                            stylist.average_response_time
                                        )}
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Container>
                </Nav>
                <Nav className="justify-content-end booking-btn-container">
                    {showBookStylistButton && (
                        <Button variant="warning" id="book" onClick={onButtonClick}>
                            {stylist.has_active_session || hasActiveSubscription ? (
                                <span>{texts.session}</span>
                            ) : (
                                <span>
                                    {texts.book} {stylist.first_name}
                                </span>
                            )}
                        </Button>
                    )}

                    {showContactStylistButton && waitListStatus !== undefined && (
                        <Button
                            variant={waitListStatus ? 'light' : 'warning'}
                            disabled={waitListActionLoading}
                            onClick={() => onWaitListButtonClick(!waitListStatus)}>
                            {waitListStatus ? texts.leaveWaitList : texts.joinWaitList}
                        </Button>
                    )}
                </Nav>
            </Container>
        </Navbar>
    );
};

export default BookBar;
