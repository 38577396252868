import { connect } from 'react-redux';

import Orders from './Orders';

const mapStateToProps = ({ users: { loading, ordersTotal }, user: { user } }) => ({
    loading,
    user,
    ordersTotal
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
