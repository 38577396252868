import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { updateItem } from '../../redux/reducers/style/actions';
import { addToCart, removeFromCart } from '../../redux/reducers/users/actions';
// TODO: need to move to some common.
import { loadInbox } from '../../routes/Inbox/store/actions';
import Chat from './Chat';

const mapStateToProps = ({
    users: { loading, channel, cart },
    services: { isTwilioInitialized },
    inbox: { messages }
}) => ({
    loading,
    channel,
    isTwilioInitialized,
    messages,
    cart
});

const mapDispatchToProps = (dispatch) => ({
    updateItem: (item) => dispatch(updateItem(item)),
    addToCart: (item, source) => dispatch(addToCart(item, source)),
    removeFromCart: (item) => dispatch(removeFromCart(item)),
    loadInbox: () => loadInbox(dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chat));
