import './style.scss';

import React, { useEffect, useState } from 'react';
import { Accordion, Button, Col, Container, Form, Image, Modal, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

import { Loader } from '../../../components';
import { modal } from '../../../content';
import { User } from '../../../services';

const AddClosetItem = ({ user, addClosetItem }) => {
    const [item, setItem] = useState({});
    const [sections, setSections] = useState({
        category: [],
        colors: [],
        seasons: []
    });
    const [error, setError] = useState(null);
    const [colorsPanel, setColorsPanel] = useState(false);
    const [loading, setLoading] = useState(false);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png',
        onDrop: (files) => {
            const file = files[0];
            if (file) {
                setError(null);
                const reader = new FileReader();
                reader.onload = (event) => {
                    setItem({
                        ...item,
                        picture: event.target.result
                    });
                };
                reader.readAsDataURL(file);
            } else {
                setError(modal.addItem.error.unsupportedFile);
            }
        }
    });

    const loadQuizData = async () => {
        // setLoading(true);
        const { data } = await User.closetFilters({
            user_uuid: user.user_uuid,
            params: {
                fields: 'categories,colors',
                refinements: 'ALL'
            }
        });
        setSections({
            category: data.find((filter) => filter.key === 'category').filter_options,
            colors: data.find((filter) => filter.key === 'colors').filter_options,
            seasons: data.find((filter) => filter.key === 'seasons').filter_options
        });
        // setLoading(false);
    };
    useEffect(() => {
        if (user) loadQuizData();
    }, [user]);

    const onSubmit = () => {
        setLoading(true);
        addClosetItem({
            ...item,
            picture: item.picture.substr(item.picture.lastIndexOf('base64') + 7),
            owner_uuid: user.user_uuid,
            type: 'user'
        });
    };

    const updateItem = (update) => {
        if (update.key === 'seasons' || update.key === 'colors') {
            let _arr = [];
            if (item[update.key]) _arr = item[update.key];
            update.value = _arr.includes(update.value)
                ? _arr.filter((item) => item !== update.value)
                : [..._arr, update.value];
        }
        setItem({
            ...item,
            [update.key]: update.value
        });
    };
    return (
        <span className="add-item">
            {loading && <Loader />}
            <Modal.Header closeButton />
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            {item.picture ? (
                                <div className="preview">
                                    <div
                                        className="remove-image"
                                        onClick={() =>
                                            updateItem({
                                                key: 'picture',
                                                value: null
                                            })
                                        }
                                    />
                                    <Image src={item.picture} alt="" />
                                </div>
                            ) : (
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <strong>+</strong>
                                    {error ? (
                                        <p className={'add-item error'}>{error}</p>
                                    ) : (
                                        <p>{modal.addItem.drag}</p>
                                    )}
                                </div>
                            )}
                        </Col>
                        <Col>
                            <Form>
                                <Form.Group controlId="category">
                                    <Form.Label>*{modal.addItem.category}</Form.Label>
                                    <div className="category">
                                        <Form.Control
                                            className={!item.category ? 'placeholder' : ''}
                                            as="select"
                                            onChange={(e) =>
                                                updateItem({
                                                    key: 'category',
                                                    value: e.target.value
                                                })
                                            }
                                        >
                                            <option disabled selected>
                                                {modal.addItem.categoryPlaceholder}
                                            </option>
                                            {sections.category.map(({ key, text }) => (
                                                <option key={key} value={key}>
                                                    {text}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </div>
                                </Form.Group>

                                <Form.Group controlId="brand">
                                    <Form.Label>{modal.addItem.brand}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={(e) =>
                                            updateItem({
                                                key: 'brand',
                                                value: e.target.value
                                            })
                                        }
                                    />
                                </Form.Group>

                                <Accordion>
                                    <Form.Group controlId="color">
                                        <Accordion.Toggle
                                            as={Form.Label}
                                            eventKey="0"
                                            onClick={() => setColorsPanel(!colorsPanel)}
                                        >
                                            {modal.addItem.color}
                                            <div className={`arrow ${colorsPanel ? 'open' : ''}`} />
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <div className="colors">
                                                {sections.colors.map(({ key, picture }) => (
                                                    <Image
                                                        key={key}
                                                        src={picture}
                                                        className={`color ${
                                                            item.colors && item.colors.includes(key)
                                                                ? 'selected'
                                                                : ''
                                                        }`}
                                                        onClick={() =>
                                                            updateItem({
                                                                key: 'colors',
                                                                value: key
                                                            })
                                                        }
                                                    />
                                                ))}
                                            </div>
                                        </Accordion.Collapse>
                                    </Form.Group>
                                </Accordion>

                                <Form.Group controlId="season">
                                    <Form.Label>{modal.addItem.season}</Form.Label>
                                    <div className="seasons">
                                        {sections.seasons.map(({ key, text }) => (
                                            <div
                                                key={key}
                                                className={`season ${
                                                    item.seasons && item.seasons.includes(key)
                                                        ? 'selected'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    updateItem({
                                                        key: 'seasons',
                                                        value: key
                                                    })
                                                }
                                            >
                                                <div className={`season-icon ${key}`} />
                                                {text}
                                            </div>
                                        ))}
                                    </div>
                                </Form.Group>

                                <Button
                                    variant="dark"
                                    onClick={onSubmit}
                                    disabled={!item.category || !item.picture || loading}
                                >
                                    {modal.addItem.save}
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </span>
    );
};

export default AddClosetItem;
