import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { Actions } from '../actions';
import { ActionTypes, BookingState } from './types';

const initialState: BookingState = {
    loading: false,
    goal: null,
    plan: null,
    stylist: null,
    campaign: null,
    bookings: [],
    totalBookings: 0,
    filter: {
        search: '',
        status: {
            key: ''
        }
    },
    bookingStatuses: [],
    clientsForStylist: []
};

const bookingReducer = (state = initialState, action: Actions): BookingState => {
    switch (action.type) {
        case ActionTypes.BOOK_STYLIST: {
            return {
                ...state,
                stylist: action.payload,
                goal: null,
                plan: null
            };
        }
        case ActionTypes.SELECT_GOAL: {
            return {
                ...state,
                goal: action.payload
            };
        }
        case ActionTypes.SELECT_PLAN: {
            return {
                ...state,
                plan: action.payload
            };
        }
        case ActionTypes.CAMPAIGN_BOOKING: {
            return {
                ...state,
                campaign: action.payload
            };
        }
        default:
            return state;
    }
};
export const name = 'booking';
export const useBookingStore: TypedUseSelectorHook<BookingState> = (selector, ...args) =>
    useSelector((store: any) => selector(store[name]), ...args);
export default bookingReducer;
