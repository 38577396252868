import config from './config';
import onboarding from './onboarding';
import payments from './payments';
import quiz from './quiz';
import style from './style';
import stylists from './stylists';
import users from './users';

export default {
    users,
    config,
    onboarding,
    style,
    stylists,
    payments,
    quiz
};
