import './style.scss';

import React, { useEffect, useState } from 'react';
import { Accordion, Button, Col, Container, Image, Navbar, Row } from 'react-bootstrap';
import { MP_EVENTS, trackEvent } from 'services/mixpanel';

import campaigns from '../../campaigns';
import { Page, StickyButton } from '../../components';
import { logo, onboarding } from '../../content';
import { Session, Tracking } from '../../services';
import Formatter from '../../services/Formatter';
import { useHistory } from 'react-router-dom';

const OnBoardingIntro = ({ resetOnboarding, steps }) => {
    const [stepsOpen, setStepsOpen] = useState(false);
    const history = useHistory();
    const campaign = Formatter.queryString(window.location.search).campaign;
    const campaignData = campaign && campaigns[campaign]?.onboarding?.intro;
    const pageData = { ...onboarding.intro, ...campaignData };

    const goToOnboarding = () => {
        history.push(`/onboarding/${steps[0].name}`);
    };

    useEffect(() => {
        Session.remove('onboarding');
        resetOnboarding();

        trackEvent({
            name: MP_EVENTS.ONBOARDING_INTRO_VIEWS
        });
        Tracking.tag({ event: 'ws.beginOnboardingQuiz' });
    }, [resetOnboarding]);

    return (
        <Page header={false} footer={false} fixedHeader={false}>
            <Container className="onboarding-intro no-padding" fluid>
                <Navbar className="nav-bar">
                    <Navbar.Brand href="/" className="logo">
                        <Image src={logo.black} className="d-flex d-sm-none" />
                        <Image src={logo.text_black} className="d-none d-sm-flex" />
                    </Navbar.Brand>
                </Navbar>

                <Row className="intro">
                    <Col>
                        <Image src={pageData.img} className="image" />
                        <p className="text">{pageData.text}</p>

                        <Button
                            id="intro-btn"
                            variant="warning"
                            className={pageData.steps ? 'd-flex' : 'd-none d-sm-flex'}
                            onClick={goToOnboarding}
                        >
                            {pageData.button.text}
                        </Button>

                        <StickyButton
                            id="intro-btn"
                            className={pageData.steps ? 'd-none' : 'd-flex d-sm-none'}
                            url=""
                            onClick={goToOnboarding}
                            text={pageData.button.text}
                            type="warning"
                        />

                        {pageData.steps && (
                            <Accordion>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    onClick={() => setStepsOpen(!stepsOpen)}
                                    className={stepsOpen ? 'open' : ''}
                                    eventKey="0"
                                >
                                    {pageData.stepsToggle}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Container className="steps-container">
                                        {pageData.steps.map((step) => (
                                            <Row>
                                                <Col xs={12} key={step.number} className="step">
                                                    <div className="num">{step.number}</div>
                                                    <p>{step.text}</p>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Container>
                                </Accordion.Collapse>
                            </Accordion>
                        )}
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};

export default OnBoardingIntro;
