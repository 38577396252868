import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setCampaignBooking } from 'store/booking/actions';
import { loadUserMatches } from 'store/common/actions';
import { toggleModal } from 'store/ui/actions';
import { loadStylist } from '../../redux/reducers/stylists/actions';
import Stylist from './Stylist';
import { loadInbox } from 'routes//Inbox/store/actions';

const mapStateToProps = ({
    user: { user },
    stylists: { stylist, loading },
    common: { mixpanelStore }
}) => ({
    user,
    stylist,
    loading,
    mixpanelStore
});

const mapDispatchToProps = (dispatch) => ({
    loadStylist: (stylist_id) => dispatch(loadStylist(stylist_id)),
    loadUserMatches: () => loadUserMatches(dispatch),
    setCampaign: (campaign) => setCampaignBooking(dispatch, campaign),
    toggleModal: (data) => toggleModal(dispatch, data),
    loadInbox: () => dispatch(loadInbox)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Stylist));
