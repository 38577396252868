import './style.scss';

import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

const Grid = ({
    options,
    type,
    props: {
        avoid = false,
        columns = 6,
        mobile = 3,
        circle = false,
        border = false,
        opacity,
        className
    },
    onChange
}) => {
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setSelected([]);
    }, [options]);

    const onSelect = (selection) => {
        let arr = [];
        if (selected.includes(selection)) {
            arr = selected.filter((item) => item !== selection);
        } else {
            arr = [...selected, selection];
        }
        setSelected(arr);
        onChange({ selected_reply_options: arr });
    };

    return (
        <Container className={`grid ${columns === 3 ? 'narrow' : className ? className : 'wide'}`}>
            <Row>
                {options.map(({ key, text, picture }) => (
                    <Col
                        xs={mobile ? 12 / mobile : 3}
                        md={options.length > columns ? 12 / columns : true}
                        key={key}
                        id={key}
                        className={`quiz-grid-item ${selected.includes(key) ? 'selected' : ''} ${
                            circle ? 'circle' : ''
                        } ${border ? 'bordered' : ''} ${opacity ? 'opacity' : ''}`}
                        onClick={() => onSelect(key)}
                    >
                        <div className={`icon ${avoid ? 'avoid' : 'check'}`} />
                        <Image src={picture} alt={key} />
                        {text && <p>{text}</p>}
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Grid;
