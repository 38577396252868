import axios from 'axios';

import Config from './Config';
import { getUserToken } from './utils/token-utils';

const User = {
    urls: {
        favorites: ({ type = '', uuid }) =>
            type
                ? `${Config.get('apiroot2')}user/${uuid}/favorites/${type}`
                : `${Config.get('apiroot2')}user/${uuid}/favourites`,
        inbox: ({ user_uuid }) => `${Config.get('apiroot2')}user/${user_uuid}/inbox`
    },
    get: (uuid, token = undefined) => {
        return axios.get(`${Config.get('apiroot2')}user/${uuid}`, {
            headers: { token: token || getUserToken() }
        });
    },
    getItem: (item_uuid) =>
        axios.get(`${Config.get('apiroot2')}item/${item_uuid}`, {
            headers: { token: getUserToken() }
        }),
    update: (data) =>
        axios.put(`${Config.get('apiroot2')}user/${data.user_uuid}`, data, {
            headers: { token: getUserToken() }
        }),
    profilePicture: ({ uuid, token, data }) =>
        axios.post(`${Config.get('apiroot2')}user/user-profile-picture/${token}/${uuid}`, data, {
            headers: { token: getUserToken() }
        }),
    checkMessages: (uuid) =>
        axios.get(`${Config.get('apiroot2')}user/${uuid}/totalUnreadMessagesCount`, {
            headers: { token: getUserToken() }
        }),
    quizReply: (uuid, type, data) =>
        axios.put(`${Config.get('apiroot2')}quiz/${uuid}/${type}/reply`, data, {
            headers: { token: getUserToken() }
        }),
    quizQuestion: (uuid, bodyIssuesFlag) =>
        axios.get(
            `${Config.get(
                'apiroot2'
            )}quiz/${uuid}/currentQuestion?client=web&isBodyIssues=${bodyIssuesFlag}`,
            { headers: { token: getUserToken() } }
        ),
    refresh: (uuid) =>
        axios.get(`${Config.get('apiroot2')}user/${uuid}`, { headers: { token: getUserToken() } }),
    favorites: ({ type = '', uuid, params }) =>
        type
            ? axios.get(`${Config.get('apiroot2')}user/${uuid}/favorites/${type}`, {
                  params,
                  headers: { token: getUserToken() }
              })
            : axios.get(`${Config.get('apiroot2')}user/${uuid}/favourites`, {
                  params,
                  headers: { token: getUserToken() }
              }),
    favorite: ({ type = '', user_uuid, outfit_uuid, stylist_uuid }) =>
        type
            ? axios.post(
                  `${Config.get('apiroot2')}outfit/like/${outfit_uuid}`,
                  { user_uuid },
                  { headers: { token: getUserToken() } }
              )
            : axios.post(
                  `${Config.get('apiroot2')}user/${user_uuid}/favourites/${stylist_uuid}`,
                  null,
                  { headers: { token: getUserToken() } }
              ),
    unfavorite: ({ type = '', user_uuid, outfit_uuid, stylist_uuid }) =>
        type
            ? axios.delete(`${Config.get('apiroot2')}outfit/like/${outfit_uuid}`, {
                  data: { user_uuid },
                  headers: { token: getUserToken() }
              })
            : axios.delete(
                  `${Config.get('apiroot2')}user/${user_uuid}/favourites/${stylist_uuid}`,
                  { data: { user_uuid }, headers: { token: getUserToken() } }
              ),
    cart: ({ user_uuid, params }) =>
        axios.get(`${Config.get('apiroot2')}user/${user_uuid}/cart`, {
            params,
            headers: { token: getUserToken() }
        }),
    addToCart: ({ user_uuid, item_uuid }) =>
        axios.post(`${Config.get('apiroot2')}user/${user_uuid}/cart/${item_uuid}`, null, {
            headers: { token: getUserToken() }
        }),
    removeFromCart: ({ user_uuid, item_uuid }) =>
        axios.delete(`${Config.get('apiroot2')}user/${user_uuid}/cart/${item_uuid}`, {
            headers: { token: getUserToken() }
        }),
    closet: ({ user_uuid, params }) =>
        axios.get(`${Config.get('apiroot2')}user/closet/${user_uuid}/${user_uuid}`, {
            params,
            headers: { token: getUserToken() }
        }),
    orders: ({ user_uuid, params }) =>
        axios.get(`${Config.get('apiroot2')}user/${user_uuid}/orders`, {
            params,
            headers: { token: getUserToken() }
        }),
    ordersFilters: (user_uuid, params) =>
        axios.get(`${Config.get('apiroot2')}user/${user_uuid}/orders/filters`, {
            params,
            headers: { token: getUserToken() }
        }),
    addToCloset: ({ user_uuid, item_uuid }) =>
        axios.post(
            `${Config.get('apiroot2')}item/${user_uuid}`,
            { item_uuid },
            { headers: { token: getUserToken() } }
        ),
    inbox: ({ user_uuid }) =>
        axios.get(`${Config.get('apiroot2')}user/${user_uuid}/inbox`, {
            headers: { token: getUserToken() }
        }),
    styleData: (user_uuid, params) => {
        if (user_uuid) {
            return axios.get(`${Config.get('apiroot2')}user/${user_uuid}/styleData`, {
                params,
                headers: { token: getUserToken() }
            });
        }
    },
    styleOptions: (params) =>
        axios.get(`${Config.get('apiroot2')}user/styleOptions`, {
            params,
            headers: { token: getUserToken() }
        }),
    stylingPreferences: ({ user_uuid, data }) =>
        axios.put(`${Config.get('apiroot2')}user/${user_uuid}/stylingPreferences`, data, {
            headers: { token: getUserToken() }
        }),
    style: (user_uuid) =>
        axios.get(`${Config.get('apiroot2')}user/${user_uuid}/style`, {
            headers: { token: getUserToken() }
        }),
    quizResponses: ({ user_uuid, token }) => {
        return axios.get(`${Config.get('api-gw')}styleData/quizResponses`, {
            params: { userUuid: user_uuid },
            headers: { token }
        });
    },

    photos: ({ user_uuid, params }) => {
        if (user_uuid) {
            return axios.get(`${Config.get('apiroot2')}user/${user_uuid}/photos`, {
                params,
                headers: { token: getUserToken() }
            });
        }
    },
    photo: ({ user_uuid, data, token }) => {
        if (user_uuid) {
            return axios.post(`${Config.get('apiroot2')}user/${user_uuid}/photos`, data, {
                headers: { token }
            });
        }
    },
    looks: ({ user_uuid, params }) =>
        axios.get(`${Config.get('apiroot2')}user/${user_uuid}/looks`, {
            params,
            headers: { token: getUserToken() }
        }),
    notes: (user_uuid, params) =>
        axios.get(`${Config.get('apiroot2')}client/${user_uuid}/notes`, {
            params,
            headers: { token: getUserToken() }
        }),
    billingDetails: (user_uuid) =>
        axios.get(`${Config.get('apiroot2')}user/${user_uuid}/billingDetails`, {
            headers: { token: getUserToken() }
        }),
    closetFilters: ({ user_uuid, params }) =>
        axios.get(`${Config.get('apiroot2')}user/${user_uuid}/closet/filters`, {
            params,
            headers: { token: getUserToken() }
        }),
    looksFilters: ({ user_uuid, params }) =>
        axios.get(`${Config.get('apiroot2')}user/${user_uuid}/looks/filters`, {
            params,
            headers: { token: getUserToken() }
        }),
    updateBillingDetails: ({ user_uuid, data }) =>
        axios.put(`${Config.get('apiroot2')}user/${user_uuid}/billingDetails`, data, {
            headers: { token: getUserToken() }
        }),
    cartFilters: (user_uuid, params) =>
        axios.get(`${Config.get('apiroot2')}user/${user_uuid}/cart/filters`, {
            params,
            headers: { token: getUserToken() }
        }),
    removeItem: (item_uuid) =>
        axios.delete(`${Config.get('apiroot2')}item/${item_uuid}`, {
            headers: { token: getUserToken() }
        }),
    removeLook: ({ user_uuid, look_uuid }) =>
        axios.put(`${Config.get('apiroot2')}user/${user_uuid}/outfits/${look_uuid}/hide`, null, {
            headers: { token: getUserToken() }
        }),
    removePhoto: (photo_uuid) =>
        axios.delete(`${Config.get('apiroot2')}user/photos/${photo_uuid}`, {
            headers: { token: getUserToken() }
        }),
    quizData: (params) =>
        axios.get(`${Config.get('apiroot2')}user/quizData`, {
            params,
            headers: { token: getUserToken() }
        }),
    addItem: (item) =>
        axios.post(`${Config.get('apiroot2')}item`, item, { headers: { token: getUserToken() } }),
    closetTags: ({ user_uuid, params }) =>
        axios.get(`${Config.get('apiroot2')}user/${user_uuid}/closetTags`, {
            params,
            headers: { token: getUserToken() }
        }),
    removeTag: ({ user_uuid, params }) =>
        axios.delete(`${Config.get('apiroot2')}user/${user_uuid}/closetTags`, {
            params,
            headers: { token: getUserToken() }
        }),
    sessions: (user_uuid) =>
        axios.get(`${Config.get('apiroot2')}user/${user_uuid}/sessions`, {
            headers: { token: getUserToken() }
        }),
    getActiveSession: (user_uuid) =>
        axios.get(`${Config.get('apiroot2')}user/${user_uuid}/hasActiveSessions`, {
            headers: { token: getUserToken() }
        }),
    deactivate: ({ user_uuid, token }) =>
        axios.put(`${Config.get('apiroot2')}user/${user_uuid}/deactivateAccount`, null, {
            headers: { token }
        }),
    getStyleData: async (user) => {
        return new Promise(async (resolve, reject) => {
            try {
                const style = await User.styleData(user.user_uuid);
                const options = await User.styleOptions({ gender: user.gender || 'female' });

                const _options = options.data.map((option) => {
                    if (User.getStyleKey(option.key)) option.key = User.getStyleKey(option.key);
                    return option;
                });
                const _style = _options.reduce((ac, a) => {
                    let value = style.data[User.getStyleKey(a.key) || a.key];
                    let key = a.key !== 'tag_uuids' ? User.getStyleKey(a.key) : a.key;
                    // Colors selection value handling in case all colors were selected
                    if (a.key === 'colors' && value[0] === 'COLOR_ALL')
                        value = a.reply_options.map((color) => color.key);
                    // Sizes selection values handling, region code removal, and default value definition
                    if (a.key === 'sizes') {
                        value = a.reply_options
                            .filter((option) =>
                                user.country && user.country === 'United Kingdom'
                                    ? option.key.includes('_UK')
                                    : !option.key.includes('_UK')
                            )
                            .map(({ key, reply_options }) => {
                                const selected = style.data[User.getStyleKey(key)]
                                    ? style.data[User.getStyleKey(key)]
                                          .replace(' US', '')
                                          .replace(' EUR', '')
                                    : reply_options[0].text;
                                const _val = reply_options.find(
                                    (option) =>
                                        option.text.replace(' US', '').replace(' EUR', '') ===
                                        selected
                                );
                                return {
                                    key,
                                    value: _val ? _val.key : reply_options[0].text
                                };
                            });
                    }
                    // Budget selection values handling, and default value definition
                    if (a.key === 'budget_ranges') {
                        value = a.reply_options
                            .filter((option) =>
                                user.country && user.country === 'United Kingdom'
                                    ? option.key.includes('_EUR')
                                    : !option.key.includes('_EUR')
                            )
                            .map(({ key, reply_options }) => {
                                const _val = style.data[a.key].find(
                                    (range) => range.name === User.getStyleKey(key)
                                );
                                return {
                                    key,
                                    value: _val ? _val.ranges[0] : reply_options[0].text
                                };
                            });
                    }
                    if (a.key === 'style_icons')
                        value = a.reply_options.filter((option) => value.includes(option.text));
                    if (a.key === 'tag_uuids')
                        value = value.map(({ uuid, name }) => ({ key: uuid, text: name }));
                    if (a.key === 'styles') {
                        value = style.data.style_tags.map(({ name }) => ({ key: name }));
                    }
                    return { ...ac, [key]: Array.isArray(value) ? value : value ? [value] : [] };
                }, {});
                // Adding social data to the styling selection data
                Object.keys(style.data).map((key) => {
                    if (key.includes('_username')) _style[key] = style.data[key];
                    if (key === 'body_issues_details' || key === 'body_issues_other')
                        _style[key] = style.data[key];
                });
                resolve({
                    style: _style,
                    options: _options
                });
            } catch (e) {
                reject(e);
            }
        });
    },
    getStyleKey: (key) => {
        // Mapping for user selections keys to styling options keys due to major differences between the APIs data
        const STYLE_MAPPING = {
            body_type: 'tag_uuids',
            body_tags: 'tag_uuids',
            tag_uuids: 'body_tags',
            style_tags: 'styles',
            budget: 'budget_ranges',
            what_matters_to_you: 'what_matters',
            jeans_type: 'jeans',
            highlights: 'highlight',
            SIZE_TOPS_US: 'top_size',
            SIZE_TOPS_UK: 'top_size',
            SIZE_BOTTOM_US: 'bottom_size',
            SIZE_BOTTOM_UK: 'bottom_size',
            SIZE_DRESS_US: 'dress_size',
            SIZE_DRESS_UK: 'dress_size',
            SIZE_SHOES_US: 'shoes_size',
            SIZE_SHOES_UK: 'shoes_size',
            BUDGET_TOPS_USD: 'blouses',
            BUDGET_TOPS_EUR: 'blouses',
            BUDGET_BOTTOM_USD: 'bottoms',
            BUDGET_BOTTOM_EUR: 'bottoms',
            BUDGET_SHOES_USD: 'shoes',
            BUDGET_SHOES_EUR: 'shoes',
            BUDGET_BAGS_USD: 'bags',
            BUDGET_BAGS_EUR: 'bags',
            BUDGET_ACCESSORIES_USD: 'accessories',
            BUDGET_ACCESSORIES_EUR: 'accessories',
            BODY_ISSUES_ARMS: 'Arms/Shoulders',
            BODY_ISSUES_STOMACH: 'Stomach',
            BODY_ISSUES_REAR: 'Rear',
            BODY_ISSUES_HIPS: 'Hips',
            BODY_ISSUES_LEGS: 'Legs',
            BODY_ISSUES_CHEST: 'Chest',
            BODY_ISSUES_FOOT: 'Feet',
            BODY_ISSUES_HEALTH_CONCERNS: 'Health Concerns',
            BODY_ISSUES_OTHER: 'Something else'
        };
        return STYLE_MAPPING[key] || key;
    },
    getStylistWaitListStatus: ({ userId }) =>
        axios.get(`${Config.get('apiroot2')}user/${userId}/waitingList`, {
            headers: { token: getUserToken() }
        }),
    leaveStylistWaitList: ({ userId, stylistId }) =>
        axios.delete(`${Config.get('apiroot2')}user/waitingList/${stylistId}`, {
            headers: { token: getUserToken(), 'X-User-Uuid': userId }
        })
};

export default User;
