import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';

import React from 'react';
import { Card, Row } from 'react-bootstrap';
import Slider from 'react-slick';

import { giftcards } from '../../../content';

const Info = () => (
    <Row className="info">
        <p className="title">{giftcards.info.title}</p>
        <Slider
            className="info-carousel"
            dots={false}
            infinite={false}
            centerMode={true}
            centerPadding="20px"
            slidesToShow={3}
            slidesToScroll={1}
            autoplay={false}
            responsive={[
                {
                    breakpoint: 1060,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 765,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]}
        >
            {giftcards.info.content.map((content) => (
                <Card key={content.title}>
                    <Card.Body>
                        <Card.Title>{content.title}</Card.Title>
                        <Card.Text>{content.text}</Card.Text>
                    </Card.Body>
                </Card>
            ))}
        </Slider>
    </Row>
);

export default Info;
