import { MiddlewareAPI, Middleware, Store, AnyAction, Dispatch } from 'redux';
import { IActionWithPayload } from '../types';

const logger: Middleware =
    (store: MiddlewareAPI<Dispatch<AnyAction>, Store>) =>
    (next) =>
    <A extends IActionWithPayload>(action: A) => {
        next(action);
    };

export default logger;
