import './style.scss';

import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Loader } from '../../components';
import { CartItem } from './';

export default ({
    cartLoading,
    cart = [],
    cartTotal,
    activeCartFilter,
    loadCart,
    className = ''
}) => {
    const PAGE_ITEMS = 5;
    const [list, setList] = useState([]);
    const [retailers, setRetailers] = useState({});

    useEffect(() => {
        if (activeCartFilter === 'retailer' && cart) {
            setRetailers(
                cart.reduce((obj, key) => {
                    if (!obj[key.retailer_name]) obj[key.retailer_name] = [];
                    obj[key.retailer_name].push(key);
                    return obj;
                }, {})
            );
        }
        setList(cart && cart.length ? cart : []);
    }, [activeCartFilter, cart]);

    return (
        <Container className={`cart ${className}`}>
            {cartLoading && <Loader />}
            {cart && (
                <InfiniteScroll
                    style={{ overflow: 'visible' }}
                    dataLength={list.filter((item) => item.is_in_closet !== true).length}
                    next={() => {
                        if (!cartLoading && activeCartFilter !== 'retailer') {
                            loadCart({ from: list.length, count: PAGE_ITEMS });
                        }
                    }}
                    hasMore={list.length < cartTotal}
                >
                    {activeCartFilter !== 'retailer'
                        ? list.map((item) => <CartItem key={item.item_uuid} item={item} />)
                        : Object.keys(retailers).map((key) => (
                              <Container className="retailer" key={key}>
                                  <p>{key}</p>
                                  {retailers[key].map((item) => (
                                      <CartItem key={item.item_uuid} item={item} />
                                  ))}
                              </Container>
                          ))}
                </InfiniteScroll>
            )}
        </Container>
    );
};
