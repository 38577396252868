import './style.scss';

import React, { useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FavoriteButton } from '../../components';
import { feed } from '../../content';
import { useUserName } from '../../hooks';
import { useIsMobile } from 'hooks';
import { trackEvent, MP_EVENTS, MP_PROPS, MP_VALUES } from 'services/mixpanel';
import { getOutfitUrl } from 'services/utils/url-utils';
import { useHistory } from 'react-router-dom';
import { capitalize } from 'services/utils/string-utils';

const FeedItem = ({
    itm: { outfit, stylist },
    isFavorite = false,
    setBookingSource,
    updateFavoriteLook
}) => {
    const history = useHistory();
    const { fullname } = useUserName(stylist);
    const [hover, setHover] = useState(false);
    const isMobile = useIsMobile();
    const eventProperties = {
        [MP_PROPS.LOOK_UUID]: outfit.uuid,
        [MP_PROPS.LOOK_SOURCE]: MP_VALUES.FEED,
        [MP_PROPS.LOOK_URL]: getOutfitUrl(outfit.uuid),
        [MP_PROPS.STYLIST_NAME]: stylist.first_name.concat(' ', stylist.last_name),
        [MP_PROPS.STYLIST_UUID]: stylist.uuid
    };
    return (
        <Container className={`feed-item type-${outfit.type}`} id={outfit.uuid}>
            <Row>
                <Image className="profile-image" src={stylist.picture} />
            </Row>
            <Row>
                <Col className="title">
                    {feed.item[outfit.type === 'moodboard' ? 'moodboard' : 'look'].replace(
                        '%stylist%',
                        fullname
                    )}
                </Col>
            </Row>
            {outfit.outfit_name && (
                <Row>
                    <Col className="name">{capitalize(outfit.outfit_name)}</Col>
                </Row>
            )}
            <div className="outfit-image-container">
                <Image className="outfit-image" src={outfit.picture} />
                <div
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className={`outfit-hover ${hover ? 'hover' : ''}`}>
                    <FavoriteButton
                        isFavorite={isFavorite}
                        onClick={() => {
                            if (!isFavorite)
                                trackEvent({
                                    name: MP_EVENTS.LOOK_ADDED_TO_FAVORITE,
                                    properties: eventProperties
                                });
                            updateFavoriteLook(outfit, 'feed');
                        }}
                        filled={hover && !isMobile}
                    />
                    <div
                        className="hover-button"
                        onClick={() => {
                            trackEvent({
                                name: MP_EVENTS.LOOK_CLICKS,
                                properties: eventProperties
                            });
                            history.push(`/feed/outfit/${outfit.uuid}`);
                        }}
                    />
                </div>
            </div>
            <Row>
                <Col>
                    <Link
                        className="btn btn-warning"
                        to={{
                            pathname: `/stylist/${stylist.uuid}/profile`,
                            state: { from: window.location.pathname }
                        }}
                        onClick={() => {
                            setBookingSource({ source: MP_VALUES.FEED, look_uuid: outfit.uuid });
                            trackEvent({
                                name: MP_EVENTS.FEED_BOOK_STYLIST,
                                properties: eventProperties
                            });
                        }}>
                        {feed.item.button.replace('%stylist%', stylist.first_name)}
                    </Link>
                </Col>
            </Row>
        </Container>
    );
};

export default FeedItem;
