import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { signup } from 'store/user/actions';

import FacebookButton from './FacebookButton';

const mapStateToProps = ({ user: { user }, onboarding }) => ({
    user,
    onboarding
});

const mapDispatchToProps = (dispatch) => ({
    signup: (credentials) => signup(dispatch, credentials)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FacebookButton));
