import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { MP_EVENTS, registerProperties, trackEvent } from 'services/mixpanel';

//import { RadioButton } from '../../../components';

const Gender = ({ data: { question, answers }, gender, selectGender, updateUser }) => {
    const onClick = (gender) => {
        registerProperties({ Gender: gender });
        trackEvent({
            name: MP_EVENTS.ONBOARDING_GENDER_SELECTION,
            properties: { Gender: gender }
        });
        selectGender(gender);
        updateUser({ gender });
    };

    useEffect(() => {
        trackEvent({ name: MP_EVENTS.ONBOARDING_GENDER_VIEWS, properties: {} });
    }, []);

    return (
        <div className="gender">
            <Col className="content">
                <p className="question">{question}</p>

                <Container>
                    <Row>
                        {answers.map(({ value, label, imageUrl }) => (
                            <div
                                key={value}
                                className={`gender-button ${gender === value ? 'selected' : ''}`}
                                onClick={() => onClick(value)}>
                                <div className="label">{label}</div>
                                <img src={imageUrl} />
                            </div>
                        ))}
                    </Row>
                </Container>
            </Col>
        </div>
    );
};

/*<RadioButton
                                key={value}
                                id="genderCheckbox"
                                value={value}
                                isChecked={gender === value}
                                onClick={(e) => {
                                    registerProperties({ Gender: e.target.id });
                                    trackEvent({
                                        name: MP_EVENTS.ONBOARDING_GENDER_SELECTION,
                                        properties: { Gender: e.target.id }
                                    });
                                    selectGender(e.target.id);
                                    updateUser({ gender: e.target.id });
                                }}
                                label={label}
                            />*/

export default Gender;
