import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { CommonState, ActionTypes } from './types';
import { Actions } from '../actions';

const initialState: CommonState = {
    stylists: [],
    stylistsMatchs: [],
    bookingSource: undefined,
    zeStatus: false,
    mixpanelStore: {}
};

const commonReducer = (state = initialState, action: Actions): CommonState => {
    switch (action.type) {
        case ActionTypes.LOAD_STYLISTS_SUCCESS:
            return {
                ...state,
                stylists: action.payload
            };
        case ActionTypes.LOAD_MATCHES_SUCCESS:
            return {
                ...state,
                stylistsMatchs: action.payload
            };
        case ActionTypes.SET_ZE_ON:
            return {
                ...state,
                zeStatus: true
            };
        case ActionTypes.SET_ZE_OFF:
            return {
                ...state,
                zeStatus: false
            };
        case ActionTypes.SET_BOOKING_SOURCE:
            return {
                ...state,
                bookingSource: action.payload
            };
        case ActionTypes.SET_MIXPANEL_STORE:
            const currentStore = state.mixpanelStore;
            const key = Object.keys(action.payload)?.[0];
            currentStore[key] = action.payload[key];
            return {
                ...state,
                mixpanelStore: currentStore
            };
        default:
            return state;
    }
};
export const name = 'common';
export const useCommonStore: TypedUseSelectorHook<CommonState> = (selector, ...args) =>
    useSelector((store: any) => selector(store[name]), ...args);
export default commonReducer;
