import './style.scss';

import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import { modal } from '../../../content';

export default ({ data = {}, toggleModal }) => {
    const onSubmit = () => {
        toggleModal();
    };

    return (
        <span className="photo">
            <Modal.Header closeButton>
                <p>{modal.error.title}</p>
            </Modal.Header>

            <Modal.Body>
                <p>{data && data.error}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="dark" onClick={onSubmit}>
                    {modal.error.submit}
                </Button>
            </Modal.Footer>
        </span>
    );
};
