import StylistMatch from './stylistMatch';
import Pricing from './Pricing';
import Inbox from './Inbox';
import Campaign from './Campaign';
import HowItWorks from './HowItWorks';
import StylistListPage from './stylistListPage';

export default {
    StylistMatch,
    Pricing,
    Inbox,
    Campaign,
    HowItWorks,
    StylistListPage
};
