import './style.scss';

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Selectors from './Selectors';

const Budget = ({ options, onChange, mandatory, props }) => {
    const [list, setList] = useState([]);
    const [selected, setSelected] = useState({});

    useEffect(() => {
        if (options) {
            setList(options.filter((option) => option.key.includes('_US')));
        }
    }, [options]);

    const onSelect = (key, option) => {
        let obj = { ...selected };
        obj[key] = option;
        setSelected(obj);
        if (!mandatory || (mandatory && Object.keys(obj).length === list.length)) {
            onChange({
                selected_reply_options: Object.keys(obj).map((k) => ({
                    key: k,
                    selected_reply_options: [obj[k]]
                }))
            });
        }
    };

    return (
        <Container className="budget">
            <div className="d-none d-sm-block">
                {list.map((option) => (
                    <Row key={option.key}>
                        <Col>{option.text}</Col>
                        {option.reply_options.map((reply) => (
                            <Col
                                id={`budget_${reply.key}`}
                                key={reply.key}
                                onClick={() => onSelect(option.key, reply.key)}
                            >
                                <div
                                    className={`budget-square ${
                                        selected[option.key] === reply.key ? 'selected' : ''
                                    }`}
                                >
                                    {reply.text}
                                </div>
                            </Col>
                        ))}
                    </Row>
                ))}
            </div>
            <div className="d-block d-sm-none">
                <Selectors
                    options={options}
                    onChange={(selections) => {
                        if (selections.selected_reply_options.length === list.length) {
                            onChange(selections);
                        }
                    }}
                    props={props}
                />
            </div>
        </Container>
    );
};

export default Budget;
