import './style.scss';

import React, { useEffect, useState } from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';

import { Formatter } from '../../services';

const Textarea = ({ onChange, options = [], props: { placeholder } }) => {
    const [reply, setReply] = useState(
        options.length
            ? { selected_reply_options: [], additional_information: { comments: '' } }
            : { reply: '' }
    );

    const onTyping = (string) => {
        let obj = { ...reply };
        const currentText = obj.additional_information
            ? obj.additional_information.comments
            : obj.reply;
        const freeText = string.suggestion
            ? `${currentText}${currentText.length ? ',' : ''} ${string.suggestion.text}`
            : string;
        if (options.length) {
            if (string.suggestion) {
                if (!obj.selected_reply_options.includes(string.suggestion.key)) {
                    reply.selected_reply_options.push(string.suggestion.key);
                }
            }
            obj.additional_information.comments = freeText;
        } else {
            obj.reply = freeText;
        }

        setReply(obj);
        onChange(obj);
    };

    return (
        <Container className={`textarea ${options.length ? 'with-badges' : ''}`}>
            <Row>
                <Col>
                    <textarea
                        id="quiz-textarea"
                        value={
                            reply.additional_information
                                ? reply.additional_information.comments
                                : reply.reply
                        }
                        onChange={(e) => onTyping(e.target.value)}
                        placeholder={placeholder}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="badges">
                    {options.map(({ text, key }, index) => (
                        <Badge
                            variant="light"
                            key={key}
                            id={`badge_${index}`}
                            onClick={() =>
                                onTyping({ suggestion: { text: Formatter.name(text), key } })
                            }
                            className={
                                reply.additional_information.comments.indexOf(text) > -1
                                    ? 'hide'
                                    : 'show'
                            }
                        >
                            {text}
                        </Badge>
                    ))}
                </Col>
            </Row>
        </Container>
    );
};

export default Textarea;
