import {
    NEXT_QUESTION_FAILED,
    NEXT_QUESTION_START,
    NEXT_QUESTION_SUCCESS,
    PREV_QUESTION,
    QUIZ_DONE,
    RESET_QUIZ,
    SET_SESSION_ID
} from './actions';

const initialState = {
    loading: false,
    session_id: null,
    questions: [],
    error: null,
    done: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SESSION_ID: {
            const { session_id } = action.payload;
            return {
                ...state,
                session_id,
                questions: []
            };
        }
        case NEXT_QUESTION_START: {
            return {
                ...state,
                loading: true
            };
        }
        case NEXT_QUESTION_SUCCESS: {
            const { question } = action.payload;
            return {
                ...state,
                loading: false,
                questions: [...state.questions, question]
            };
        }
        case NEXT_QUESTION_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                loading: false,
                error
            };
        }
        case PREV_QUESTION: {
            return {
                ...state,
                loading: false,
                questions: state.questions.slice(0, state.questions.length - 1)
            };
        }
        case QUIZ_DONE: {
            return {
                ...state,
                done: true,
                error: null
            };
        }
        case RESET_QUIZ: {
            return {
                ...state,
                questions: [],
                loading: false,
                error: null,
                done: false
            };
        }
        default:
            return state;
    }
}
