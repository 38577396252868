import './style.scss';

import ConfettiGenerator from 'confetti-js';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { quiz } from '../../content';
import { Tracking } from '../../services';

const Welcome = ({ stylist }) => {
    useEffect(() => {
        const options = {
            max: '200',
            size: '1',
            animate: true,
            props: ['circle'],
            colors: [
                [208, 171, 109],
                [238, 208, 146],
                [252, 221, 158]
            ],
            clock: '120',
            width: `${window.innerWidth / 4}`,
            rotate: false,
            start_from_edge: true,
            respawn: false
        };
        const confettiRight = new ConfettiGenerator({ ...options, target: 'confetti-right' });
        const confettiLeft = new ConfettiGenerator({ ...options, target: 'confetti-left' });
        confettiRight.render();
        confettiLeft.render();

        Tracking.google({
            type: 'event',
            event: 'conversion',
            data: { send_to: 'AW-870964131/dOZnCJfejfEBEKO3p58D' }
        });

        return () => {
            confettiRight.clear();
            confettiLeft.clear();
        };
    }, []);
    return (
        <Container className="quiz-welcome">
            <canvas id="confetti-right" className="confetti right" />
            <canvas id="confetti-left" className="confetti left" />
            <Row>
                <Col>
                    <p className="title">
                        {quiz.welcome.title.replace('%stylist%', stylist?.name || '')}
                    </p>
                    <p className="description">{quiz.welcome.description}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Link id="LetsGoQuiz" to="/quiz">
                        {quiz.welcome.button}
                    </Link>
                </Col>
            </Row>
        </Container>
    );
};

export default Welcome;
