import './style.scss';

import { useIsMobile } from 'hooks';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { shopping } from '../../content';

const ShoppingFilter = ({ filter, clear, activeCartFilter }) => {
    const isMobile = useIsMobile();
    useEffect(() => {
        clear();
    }, [clear]);

    return (
        <Container className="shopping-list-filter">
            <Row>
                <Col className="filter-btns">
                    {shopping.filter.title}
                    {shopping.filter.filters.map(({ name, mobileName, key, value }) => (
                        <div
                            key={key}
                            className={`filter-btn ${activeCartFilter === key ? 'active' : ''}`}
                            onClick={() => filter({ key, value })}
                        >
                            {isMobile && mobileName ? mobileName : name}
                        </div>
                    ))}
                </Col>
            </Row>
        </Container>
    );
};

export default ShoppingFilter;
