import './style.scss';

import loadImage from 'blueimp-load-image';
import React, { useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import ExifOrientationImg from 'react-exif-orientation-img';

import { quiz } from '../../content';

const Upload = ({ onChange }) => {
    const [picture, setPicture] = useState('');

    const onPictureChange = (e) => {
        loadImage(
            e.target.files[0],
            (img) => {
                if (img.toDataURL) {
                    const base64 = img.toDataURL().substr(img.toDataURL().indexOf(',') + 1);
                    setPicture(base64);
                    onChange({ reply: base64 });
                }
            },
            { orientation: true, canvas: true }
        );
    };

    const removePicture = () => {
        setPicture('');
        onChange('');
    };

    return (
        <Container className="upload">
            <Row>
                {picture.length > 0 && (
                    <Col key={picture} className="upload-image">
                        <div className="remove" onClick={removePicture}>
                            <span className="close" />
                        </div>
                        <ExifOrientationImg src={`data:image/jpeg;base64,${picture}`} />
                    </Col>
                )}

                {!picture.length && (
                    <Col className="upload-btn" id="upload">
                        <label htmlFor="review-image">
                            <Image src={quiz.questions.button.upload} />
                        </label>
                        <input
                            type="file"
                            id="review-image"
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={onPictureChange}
                        />
                    </Col>
                )}
            </Row>
        </Container>
    );
};

export default Upload;
