import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { loadInbox } from '../../routes/Inbox/store/actions';
import _Quiz from './Quiz';
import _Welcome from './Welcome';

const mapStateToProps = ({
    user: { user },
    booking: { stylist },
    inbox: { messages },
    payments: { price }
}) => ({
    user,
    stylist,
    messages,
    price
});

const mapDispatchToProps = (dispatch) => ({
    loadInbox: () => loadInbox(dispatch)
});
export const Welcome = withRouter(connect(mapStateToProps, mapDispatchToProps)(_Welcome));
export const Quiz = withRouter(connect(mapStateToProps, mapDispatchToProps)(_Quiz));
