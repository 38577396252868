import './style.scss';

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { Page } from '../../components';

const Privacy = () => (
    <Page>
        <Container className="privacy">
            <Row>
                <Col xs={12}>
                    <article>
                        <h2>PRIVACY POLICY</h2>
                        <p>
                            Wishi Fashion Ltd. and its affiliates (<strong>"Company"</strong> or{' '}
                            <strong>"we"</strong>) respect the privacy of its users (
                            <strong>"User"</strong> or <strong>"you"</strong>) and are committed to
                            protect the User's information. We believe that you have a right to know
                            our practices regarding the information we may collect and use when you
                            use our online social fashion network (the <strong>"Service"</strong>).
                            The Service enables you to upload your Photos of your real clothes and
                            share them with other Users on the Service. We offer our Service online
                            on the Facebook App center, or on our site at:{' '}
                            <a href="<?=PATH?>">http://www.wishi.me/</a> (the{' '}
                            <strong>"Site"</strong>) through Facebook connection and on mobile
                            device.
                        </p>
                        <h4>
                            <strong>Your consent (important, please read carefully!)</strong>
                        </h4>
                        <p>
                            By accessing and/or using the Site or the Service, you agree to the
                            terms and conditions set forth in this Privacy Policy, including to the
                            collection and processing of your Personal Information (as defined
                            below). You will also be asked to accept the terms of the policy while
                            registering to the service by clicking the "Play Game" box. Please note:
                            if you disagree to any term provided herein, you may not access or use
                            the Service.
                        </p>
                        <h4>
                            <strong>How do we collect information about you?</strong>
                        </h4>
                        <p>
                            We only collect information about you through our Service, as follows:{' '}
                            <br />
                            · When you use our Service for the first time, you are requested to
                            allow the Service to collect certain Personal Information from your
                            Facebook account, as described below.
                            <br />· When you upload, edit and/or save your Photos or any other
                            information on the Service.
                        </p>
                        <h4>
                            <strong>Which information we may collect?</strong>
                        </h4>
                        We collect two types of data and information:
                        <br />
                        <strong>1. Non-personal Information. </strong>
                        <br />
                        The first type is non-identifiable and anonymous information. To put it
                        simply, we have no idea what is the identity of the User from which we have
                        collected the Non-personal Information. Non-personal Information which is
                        being gathered consists of technical information and behavioral information,
                        as detailed below:
                        <strong className="semi">
                            <br />· Technical information:{' '}
                        </strong>
                        <ul>
                            <li>Type of operation system (e.g. Windows, Linux, etc.)</li>
                            <li>
                                Android or iOs Operating System Vendor (e.g. Galaxy, HTC, iPhone,
                                etc.)
                            </li>
                            <li>Type of Browser (e.g. Explorer, Firefox, Chrome, Safari, etc.)</li>
                            <li>Screen resolution (e.g. 800x600, 1024x768, etc.)</li>
                            <li>Flash Version.</li>
                            <li>Java Support</li>
                            <li>Screen Colors</li>
                            <li>Service Provider</li>
                            <li>Host Name</li>
                            <li>Mobile Device and Mobile Carrier</li>
                            <li>IP address</li>
                            <li>Language (e.g. English)</li>
                        </ul>
                        <strong className="semi">
                            <br />· Behavioral information:{' '}
                        </strong>
                        <ul>
                            <li>User's interaction with the Service such as:</li>
                            <li>Viewing and Choosing photos from other closets on the Service;</li>
                            <li>Switching between categories;</li>
                            <li>
                                Users' saving and sharing photos with other User on the Service;
                            </li>
                            <li>Any Similar behavioral information.</li>
                        </ul>
                        <strong className="semi">
                            <br />
                            Personal Information.
                        </strong>
                        <br />
                        The other type of information we may collect is individually identifiable
                        information. To put it simply, this information may identify an individual
                        and/or is of a private and/or sensitive nature such as your name, email
                        address, birth date, photos etc. Personal Information gathered through the
                        Service includes your basic info on your Facebook account (such as name,
                        gender, profile picture, lists of friends, networks, etc.), email address,
                        birth date, your events and your friends' events shared with you on
                        Facebook, as well as any type of Personal Information you made public on
                        your Facebook account. Additional Personal Information we collect may
                        include Photos which you uploaded manually to the Service.
                        <h4>
                            <strong>What are the purposes of the collection of information?</strong>
                        </h4>
                        <ul>
                            <li>Non-personal Information is collected in order to:</li>
                            <li>Enhance the User's experience on the Service.</li>
                            <li>
                                Learn about the preferences of Users and general trends on the
                                Service.
                            </li>
                            <li>Improve the Service, its design and layout per Users' behavior.</li>
                            <li>For statistic purposes.</li>
                            <li>Personal Information is collected in order to:</li>
                            <li>Enable the overall operation of the Service.</li>
                            <li>
                                Personalize your experience on the Service according to your
                                preferences.
                            </li>
                            <li>
                                Personalize your experience on the Service according to your
                                preferences.
                            </li>
                            <li>
                                Allow us to contact you with proposals and tailored information
                                regarding events, offers, contests, services, etc.
                            </li>
                        </ul>
                        <h4>
                            <strong>Sharing Personal Information with Third Parties</strong>
                        </h4>
                        <h6>
                            <strong>
                                We share Personal Information only in the following cases:{' '}
                            </strong>
                        </h6>
                        <br />
                        <p>
                            (a) to satisfy any applicable law, regulation, legal process, subpoena
                            or governmental request; (b) to enforce this Privacy Policy or the Terms
                            of Use, including investigation of potential violations thereof; (c) to
                            detect, prevent, or otherwise address fraud, security or technical
                            issues; (d) to respond to claims that any content published on the
                            Service violates any right of a third-party; (e) to respond to claims
                            that unauthorized content (e.g. personal photos with content that may
                            violate the Terms of Use) of a third-party has been posted on the
                            Service; (f) to protect the rights, property, or personal safety of the
                            Company, the Users or the general public; (g) when Company is undergoing
                            any change in control, including by means of merger, acquisition or
                            purchase of all or substantially all of the assets of Company; (h)
                            pursuant to your approval, in order to supply certain services you have
                            requested from Company; (i) to let our partners and affiliates serve you
                            with commercials; or (j) to collect, hold and manage your Personal
                            Information through a third party's cloud based services, as reasonable
                            for Company's business purposes, which may be located in countries
                            outside of your jurisdiction, including but not limited to the United
                            States of America.
                        </p>
                        <h4>
                            <strong>Sharing Photos through the Service </strong>
                        </h4>
                        <p>
                            · Photos uploaded to the Service are public and will be shared with
                            other Users of the Service. If you would like to make certain content
                            (like closet items) on the Service private, then you may lock such
                            content into a "private drawer" which will be available only to you.
                            Otherwise, your content will be available to other Users on the Service
                            and thus there should be no expectation for privacy with respect to such
                            content.
                        </p>
                        <h4>
                            <strong>Direct Marketing &amp; Advertising</strong>
                        </h4>
                        <p>
                            · Direct Marketing: You hereby consent that we shall use your contact
                            details for the purpose of informing you regarding products and services
                            (offered by the Company and/or by third parties) which may interest you
                            and to send to you advertisements and other marketing material,
                            transmitted to the e-mail address you have provided. You may withdraw
                            your consent to receive such marketing materials by changing your
                            preferences on the setting page on the Service at:{' '}
                            <a href="<?=PATH.'settings'?>">http://www.wishi.me/settings</a>.
                        </p>
                        <p>
                            <strong>
                                It is clarified that we are not responsible for the content of said
                                advertisements and the products delivered or services rendered
                                thereby by third parties and you irrevocably and unconditionally
                                agree that we shall not be held responsible or liable in connection
                                thereof.
                            </strong>
                            <br />
                            Advertisements:&nbsp;We may accept advertisements from third party
                            advertisers. These advertisers are carefully selected to provide
                            information about products and services that we think may be of interest
                            to our Users. Note that if you click on any of these advertisements,
                            these advertisers may use cookies and other web-tracking technologies to
                            collect non-personal and/or personal information about you. We recommend
                            that you review the terms of use and privacy policy of any advertiser
                            with which you are interacting before doing so. Their privacy policy,
                            not ours, will apply to any of those interactions.
                        </p>
                        <h4>
                            <strong>Modifications to or Deletion of Personal Information</strong>
                        </h4>
                        <p>
                            At any time, you may change or update your Personal Information through
                            your Facebook profile page. If you wish to delete your account on Wishi,
                            you may remove the Wishi application from your Facebook account by
                            clicking on the "Delete" button on Wishi and your account will be
                            terminated. When you do so, you will not be able to enjoy the Service
                            any longer or recover your data (including your closet items and
                            styling). Further, the Service will not have access to your account
                            information on Facebook any longer.
                        </p>
                        <h4>
                            <strong>Cookies & Local Storage</strong>
                        </h4>
                        <p>
                            When you access or use the Service, the Company may use industry-wide
                            technologies such as "cookies" and Flash (or similar technologies),
                            which stores certain information on your computer ("Local Storage") and
                            which will allow us to enable automatic activation of certain features,
                            and make your Service experience much more convenient and effortless.
                            The cookies used by the Service are created per session, does not
                            include any information about you, other than your session key and are
                            removed as your session ends. It is easy to prohibit the Local Storage.
                            Most browsers will allow you to erase cookies from your computer's hard
                            drive, block acceptance of cookies, or receive a warning before a cookie
                            is stored. In order to erase or disable the Local Storage option in
                            Flash you should use the settings option of Flash according to the
                            specific instructions provided by the technology provider. However, if
                            you block or erase cookies, or change the settings of Flash, your online
                            experience may be limited.
                        </p>
                        <br />
                        <p>
                            We may also use certain third-party cookies (e.g. Google
                            Authentication). These are a different kind of cookies, stored on your
                            computer by third parties, rather than by the Company. This kind of
                            cookie is only read (and not altered by the Service) each time you visit
                            the Service, and also when you visit certain websites or applications
                            that uses similar cookies. Third-party cookies usually store only a
                            Non-personal Information, such as the web pages you have visited, the
                            duration of your browsing, etc.
                        </p>
                        <h4>
                            <strong>Security </strong>
                        </h4>
                        <p>
                            We take a great care in maintaining the security of the Service and your
                            information and in preventing unauthorized access to it through industry
                            standard technologies and internal procedures, including through the use
                            of encryption mechanisms and firewalls. However, we do not guarantee
                            that unauthorized access will never occur.
                        </p>
                        <h4>
                            <strong>Third Party Software/Service </strong>
                        </h4>
                        <p>
                            While using the Service we may be using third party software and/or
                            service, in order to collect and/or process the information detailed
                            herein. Such software includes without limitation, Google Analytics,
                            which privacy policy is available at{' '}
                            <a href="http://www.google.com/intl/en/analytics/privacyoverview.html">
                                http://www.google.com/intl/en/analytics/privacyoverview.html
                            </a>{' '}
                            and Amazon S3, which Privacy Policy is available at{' '}
                            <a href="http://aws.amazon.com/privacy.html">
                                http://aws.amazon.com/privacy.html
                            </a>
                            .
                        </p>
                        <h4>
                            <strong>Changes to the Privacy Policy</strong>
                        </h4>
                        <p>
                            The terms of this Privacy Policy will govern the use of the Service and
                            any information collected therein. Company reserves the right to change
                            this policy at any time, so please re-visit this page frequently. In
                            case of any material change, we will make reasonable efforts to post a
                            clear notice on the Service.
                        </p>
                        <p>
                            Changes to this Privacy Policy are effective as of the stated “Last
                            Revised” date and your continued use of the Service on or the Last
                            Revised date will constitute acceptance of, and agreement to be bound
                            by, those changes.
                        </p>
                        <h4>
                            <strong>Got any Questions? </strong>
                        </h4>
                        <p>
                            <strong>
                                If you have any questions (or comments) concerning this Privacy
                                Policy, you are most welcomed to send us an email to the following
                                address: <a href="mailto:hello@wishi.me">hello@wishi.me</a> and we
                                will make an effort to reply within a reasonable timeframe.
                            </strong>
                        </p>
                    </article>
                </Col>
            </Row>
        </Container>
    </Page>
);
export default Privacy;
