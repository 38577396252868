import './style.scss';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useReducer } from 'hooks';
import inboxReducer from './store/reducer';
import { Page, Loader } from 'components';
import { inbox } from 'content.json';
import Messages from './Messages';
import { name, useInboxStore } from './store/reducer';

const Inbox = () => {
    useReducer(name, inboxReducer);
    const loading = useInboxStore((store) => store.indicators.loading);

    return (
        <Page footer={false}>
            {loading && <Loader />}
            <Container className={`inbox`} fluid>
                <Row>
                    <Col className="title">{inbox.title}</Col>
                </Row>
                <Row>
                    <Messages />
                </Row>
            </Container>
        </Page>
    );
};

export default Inbox;
