import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadUserMatches, setBookingSource, setMixpanelStore } from 'store/common/actions';
import { loadFavoriteStylists } from 'store/user/actions';

import { loadFavoriteLooks, loadFavoriteLooksSuccess } from '../../redux/reducers/users/actions';
import Favorites from './Favorites';

const mapStateToProps = ({
    users: { favoriteLooks, loading },
    user: { user, favoriteStylists, favoriteSection },
    common: { mixpanelStore, stylistsMatchs }
}) => ({
    user,
    loading,
    favoriteLooks,
    favoriteStylists,
    favoriteSection,
    mixpanelStore,
    stylistsMatchs
});

const mapDispatchToProps = (dispatch) => ({
    loadFavoriteLooks: (params) => dispatch(loadFavoriteLooks(params)),
    loadFavoriteStylists: (params) => loadFavoriteStylists(dispatch, params),
    loadFavoriteLooksSuccess: (outfit) => dispatch(loadFavoriteLooksSuccess(outfit)),
    setBookingSource: (source) => setBookingSource(dispatch, source),
    setMixpanelStore: (data) => setMixpanelStore(dispatch, data),
    loadUserMatches: (user) => loadUserMatches(dispatch, user)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Favorites));
