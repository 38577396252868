import './style.scss';

import { useWithDispatch } from 'hooks';
import React, { useEffect, useRef } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';
import { loadStylistsNames, setMixpanelStore } from 'store/common/actions';
import { useCommonStore } from 'store/common/reducer';

import { Loader, Page, PageNavbar } from '../../components';
import { stylist as texts } from '../../content';
import { Tracking } from '../../services';
import Sections from './Sections';

const Stylist = ({
    user,
    stylist,
    match,
    loadStylist,
    loadUserMatches,
    loading,
    setCampaign,
    toggleModal,
    loadInbox
}) => {
    const history = useHistory();
    const location = useLocation();
    const reviewsRef = React.createRef();
    const aboutRef = React.createRef();
    const workRef = React.createRef();
    const NAV_ANCHORS = [
        { ref: workRef, label: 'Looks' },
        { ref: aboutRef, label: 'About' },
        { ref: reviewsRef, label: 'Reviews' }
    ];
    const matches = useCommonStore((store) => store.stylistsMatchs);
    const bookingSource = useCommonStore((store) => store.bookingSource);
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const loadStylistsNamesAction = useWithDispatch(loadStylistsNames);
    const loadUserMatchesAction = useWithDispatch(loadUserMatches);
    const tracked = useRef(false);
    const setMixpanelStoreAction = useWithDispatch(setMixpanelStore);

    const onNavSelect = (ref) => ref.current.scrollIntoView({ behavior: 'smooth' });

    const contactStylist = () => {
        user?.user_uuid
            ? toggleModal({ type: 'Contact' })
            : toggleModal({
                  type: 'Signup',
                  url: `/stylist/${stylist.uuid}/profile`,
                  data: { source: 'contact stylist' }
              });
    };

    useEffect(() => {
        setCampaign(location.state ? location.state.campaign : null);
    }, [location]);

    useEffect(() => {
        if (!matches.length) {
            loadUserMatchesAction(user);
            loadStylistsNamesAction();
        }
        if (user) loadInbox();
    }, [user]);

    useEffect(() => {
        setCampaign(location.state ? location.state.campaign : null);
    }, [location]);

    useEffect(() => {
        if (stylist.uuid && bookingSource && !tracked.current) {
            setMixpanelStoreAction({
                onboarding: {
                    ...mixpanelStore.onboarding,
                    [MP_PROPS.BOOKING_SOURCE]: bookingSource.source
                }
            });
            trackEvent({
                name: MP_EVENTS.STYLIST_PROFILE_VIEWS,
                properties: {
                    ...mixpanelStore.onboarding,
                    ...bookingSourceToProps(bookingSource)
                }
            });
            Tracking.tag({
                event: 'eec.detail',
                ecommerce: {
                    detail: {
                        actionField: { list: 'Stylist Recommendations' },
                        products: [
                            {
                                id: stylist.uuid,
                                name: stylist.name,
                                category: '/category/stylist/',
                                dimension3: 'Ecommerce'
                            }
                        ]
                    }
                }
            });
            tracked.current = true;
        }
    }, [stylist, bookingSource]);

    useEffect(() => {
        Tracking.facebook('track', 'PageView', {
            id: match.params.id,
            content_ids: [match.params.id],
            content_type: 'product'
        });
        loadStylist(match.params.id);
    }, [loadStylist, loadUserMatches, match]);

    if (!stylist) {
        return <></>;
    }

    return (
        <Page footer={false}>
            {(loading || !stylist.uuid) && <Loader />}
            {user && stylist && user.user_uuid === stylist.uuid && navigator.share && (
                <Sections.ShareBar />
            )}

            <Container className="stylist">
                <PageNavbar
                    className="d-block d-sm-none"
                    anchors={NAV_ANCHORS}
                    onSelect={onNavSelect}
                    onBack={() => history.push('/stylistSearch')}
                />

                <Sections.StylistHeader />

                <div ref={workRef}>
                    <Sections.Work />
                </div>

                <Sections.Recommendation />

                <div ref={aboutRef}>
                    <Sections.Experience />

                    <Button variant="light" className="contact-btn" onClick={contactStylist}>
                        {texts.contact.replace('%stylist%', stylist.first_name)}
                    </Button>
                </div>

                <Sections.Expertise />

                <div ref={reviewsRef}>
                    <Sections.Reviews />
                </div>

                <Sections.StylingExperience />

                <Sections.Matches />

                {!loading && stylist?.uuid && <Sections.BookBar />}
            </Container>
        </Page>
    );
};

export default Stylist;
