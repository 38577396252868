import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import { TwilioService } from 'services';
import { sentryException } from 'services/SentryLogging';

export const setTwilioInitalized = (dispatch: Dispatch, payload: boolean) => {
    dispatch({
        type: ActionTypes.SET_TWILIO_INITIALIZED,
        payload
    });
};

export const initalizedTwilio = async (dispatch: Dispatch) => {
    try {
        await TwilioService.init();
        setTwilioInitalized(dispatch, true);
    } catch (error) {
        // TODO: handle this error in a middleware.
        sentryException(error as Error, 'twilio init failed!');
        // dispatch(stopLoading(error));
    }
};
