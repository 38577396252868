import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { ErrorState } from './types';
import { ActionTypes, Actions } from '../actions';

const initialState: ErrorState = {
    statusCode: 0,
    message: '',
    subject: '',
    error: {},
    showMessage: false,
    errors: []
};

export const errorReducer = (state = initialState, action: Actions): ErrorState => {
    switch (action.type) {
        case ActionTypes.SETUP_ERROR:
            return {
                ...state,
                ...action.payload
            };
        case ActionTypes.RESET_ERROR:
            return initialState;
        default:
            return state;
    }
};
export const name = 'error';
export const useErrorStore: TypedUseSelectorHook<ErrorState> = (selector, ...args) =>
    useSelector((store: any) => selector(store[name]), ...args);

export default errorReducer;
