export const MP_EVENTS = {
    BOOK_STYLIST: 'Book Stylist',

    COMPLETED_A_QUIZ: 'Completed a Quiz',
    CHECKOUT_VIEWS: 'Checkout Views',

    END_SESSION_REQUEST: 'End Session Request',
    END_SESSION_RESPONSE: 'End Session Response',

    FEED_BOOK_STYLIST: 'Feed Book Stylist',
    FEED_PAGE_VIEWS: 'Feed Page Views',

    HOW_IT_WORKS_PAGE_VIEW: 'How it Works Page Views',
    HOW_IT_WORKS_PRICING_PLANS: 'How it Works Pricing Plans',
    HOW_IT_WORKS_GET_STYLING: 'How it Works Get Styling',
    HOW_IT_WORKS_HELP_CENTER: 'How it Works Help Center',
    HOW_IT_WORKS_HELP_WIDGET: 'How it Works Help Widget',
    HOW_IT_WORKS_REVIEWS_NAVIGATION: 'How it Works Reviews Navigation',
    HOW_IT_WORKS_STYLIST_CLICK: 'How it Works Stylist Name Click',

    ITEM_ORDERED: 'Item Ordered',
    ITEM_CLICKS: 'Item Clicks',
    ITEM_ADDED_TO_CART: 'Item Added to Cart',
    ITEM_VIEWS: 'Item Views',
    ITEM_CHECKOUT_CLICKS: 'Item Checkout Clicks',

    LOGIN: 'Login',
    LOOK_CLICKS: 'Look Clicks',
    LOOK_VIEWS: 'Look Views',
    LOOK_ADDED_TO_FAVORITE: 'Look Added to Favorite',

    MEET_MY_MATCH: 'Meet My Match',
    MEET_STYLIST: 'Meet Stylist',

    ONBOARDING_INTRO_VIEWS: 'Onboarding Intro Views',
    ONBOARDING_GENDER_VIEWS: 'Onboarding Gender Views',
    ONBOARDING_GENDER_SELECTION: 'Onboarding Gender Selection',
    ONBOARDING_BODY_TYPE_VIEWS: 'Onboarding Body Type Views',
    ONBOARDING_BODY_TYPE_SELECTION: 'Onboarding Body type Selection',
    ONBOARDING_STYLE_VIEWS: 'Onboarding Style Views',
    ONBOARDING_STYLE_SELECTION: 'Onboarding Style Selection',
    ONBOARDING_BRANDS_VIEWS: 'Onboarding Brands Views',
    ONBOARDING_BRANDS_SELECTION: 'Onbaording Brands Selection',
    ORDER_COMPLETED: 'Order Completed',

    PAYMENT_COMPLETED: 'Payment Completed',
    PLAN_SELECTION: 'Plan selection',
    PLAN_VIEWS: 'Plan Views',

    SESSION_ENDED: 'Session Ended',
    SESSION_STARTED: 'Session Started',
    SIGNUP_CLICK: 'Signup Click',
    SIGNUP_PAGE_VIEW: 'Signup Page View',
    SIGNUP_COMPLETED: 'Signup Completed',
    STARTED_A_QUIZ: 'Started a Quiz',
    STYLING_GOAL_SELECTION: 'Styling Goal Selection',
    STYLING_GOALS_VIEWS: 'Styling Goals Views',
    STYLIST_MATCH_VIEWS: 'Stylist Match Views',
    STYLIST_PROFILE_VIEWS: 'Stylist Profile Views',
    VIEW_MORE_STYLISTS_CLICK: 'View More Stylists Click',

    QUIZ_QUESTION_VIEW: 'Quiz Question View',

    JOIN_WAITLIST_CLICK: 'Join Waitlist Click',
    LEAVE_WAITLIST_CLICK: 'Leave Waitlist Click'
};
export const MP_PROPS = {
    ACTIVE_SUBSCRIPTION: 'Active Subscription',
    BIRTHDATE: 'Birthdate',
    BODY_TYPE: 'Body Type',
    BOOKING_SOURCE: 'Booking source',
    BOOKING_COUNT: 'Booking Count',
    COUNTRY: 'Country',
    COUPON_NAME: 'Coupon name',
    END_SESSION_RESPONSE: 'End session response',
    FIRST_NAME: 'First name',
    FIRST_BOOKING_DATE: 'First Booking Date',
    FIRST_ORDER_DATE: 'First Order Date',
    GENDER: 'Gender',
    IS_TRIAL: 'Is trial',
    IS_STYLIST_MATCH: 'Is stylist match',
    IS_SESSION_STYLIST_MATCH: 'Is session stylist match',
    ITEM_UUID: 'Item UUID',
    ITEM_SOURCE: 'Item source',
    ITEM_BRAND: 'Item brand',
    LAST_BOOKING_AMOUNT: 'Last Booking Amount',
    LAST_BOOKING_DATE: 'Last Booking Date',
    LAST_NAME: 'Last name',
    LAST_ORDER_DATE: 'Last Order Date',
    LAST_ORDER_AMOUNT: 'Last Order Amount',
    LOOK_UUID: 'Look UUID',
    LOOK_SOURCE: 'Look source',
    LOOK_URL: 'Look URL',
    ORDER_COUNT: 'Order Count',
    PAGE_NAME: 'Page name',
    PAYMENT_AMOUNT: 'Payment amount',
    PAYMENT_METHOD: 'Payment method',
    PAYMENT_TYPE: 'Payment type',
    PLAN_CYCLE: 'Plan cycle',
    PLAN_TYPE: 'Plan type',
    PREFERRED_STYLES: 'Preferred Styles',
    USER_TYPE: 'User Type',
    USER_STATUS: 'User status',
    USER_UUID: 'User UUID',
    USER_EMAIL: 'User email',
    SELECTED_BRAND_LIST: 'Selected brand list',
    SELECTED_GOAL: 'Selected goal',
    SESSION_UUID: 'Session UUID',
    SESSION_COUNT: 'Session count',
    SESSION_STATUS: 'Session status',
    SESSION_AMOUNT: 'Session amount',
    SIGNUP_DATE: 'Signup date',
    SIGNUP_PLATFORM: 'Signup Platform',
    STYLE_SELECTION: 'Style selection',
    STYLIST_NAME: 'Stylist name',
    STYLIST_UUID: 'Stylist UUID',
    USED_COUPON: 'Used coupon',
    UUID: 'UUID',
    SOURCE: 'Source'
};

export const MP_VALUES = {
    YES: 'Yes',
    NO: 'No',
    STYLISTS: 'stylists',
    FEED: 'feed',
    SESSION: 'session',
    FAVORITES: 'favorites',
    CART: 'cart',
    STYLIST_PROFILE: 'stylist profile',
    LANDING_PAGE: 'landing page',
    NO_ACCOUNT_LINK: 'login',
    NAVBAR: 'navbar',
    ONBOARDING_QUIZ: 'onboarding quiz'
};
