import './style.scss';

import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import { modal } from '../../../content';

const Custom = ({ onChange }) => {
    const [amount, setAmount] = useState('');
    return (
        <Form.Group className="custom-input">
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    as="input"
                    value={amount}
                    onChange={({ target: { value } }) => {
                        const amount = value.replace(/[^0-9]/gi, '');
                        setAmount(amount);
                        onChange(amount);
                    }}
                    placeholder={modal.tip.amount}
                />
            </InputGroup>
        </Form.Group>
    );
};

export default Custom;
