import campaigns from 'campaigns.json';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Gifts from './Gifts';
import Valentine from './Valentine';
import Wedding from './Wedding';

const Campaigns: Map<
    keyof typeof campaigns,
    (() => JSX.Element) | ReturnType<typeof withRouter> | ReturnType<typeof connect>
> = new Map();
Campaigns.set('gifts', Gifts);
Campaigns.set('valentine', Valentine);
Campaigns.set('wedding', Wedding as any);
export default Campaigns;
