import '../style.scss';

import { CardElement /*, Elements*/, useElements, useStripe } from '@stripe/react-stripe-js';
//import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { sentryException } from 'services/SentryLogging';

import { payment } from '../../../content';
//import { Config, Payments } from '../../../services';
import { CreditCardElement, Loader } from '../../';

//const stripePromise = loadStripe(Payments.getStripeKey());

const CreditCard = ({
    toggle,
    updateUserCards,
    updatePaymentToken,
    model,
    coupon,
    plan,
    disclaimerText = payment.details.disclaimerMobile,
    paymentButtonDisabled,
    buttonDisabled
}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        paymentButtonDisabled(true);
    }, []);

    const handleSubmit = async () => {
        if (!stripe || !elements) return;
        setLoading(true);

        let stripeToken;
        let result;

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)
        });
        try {
            result = await stripe.createToken(elements.getElement(CardElement));
            if (result.token) {
                stripeToken = result.token.id;
            }
        } catch (err) {
            sentryException(err, "Couldn't submit credit card details");
        }

        if (error) {
            setLoading(false);
            setError(error);
            sentryException(error, "Couldn't submit credit card details");
        } else {
            const card = paymentMethod.card;
            if (card) {
                updatePaymentToken(stripeToken);
                updateUserCards({
                    brand: card.brand,
                    exp_month: card.exp_month,
                    exp_year: card.exp_year,
                    last4: card.last4
                });
            }
            toggle();
        }
    };

    return (
        <Container className="credit-card">
            {loading && <Loader />}
            <Row>
                <Col>
                    <div className="back-btn no-text" onClick={toggle} />
                    <div className="title">{payment.mobile.add}</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <CreditCardElement onChange={(e) => paymentButtonDisabled(!e.complete)} />
                </Col>
            </Row>
            {error && (
                <Row>
                    <Col className="error">{error.message}</Col>
                </Row>
            )}
            {(coupon.total || !!plan) && (
                <Row>
                    <Col>
                        <div className={`disclaimer ${model === 'monthly' ? 'show' : 'hide'}`}>
                            {disclaimerText.replace(
                                '%price%',
                                coupon.total !== undefined ? coupon.total : plan.price[model]
                            )}
                        </div>
                    </Col>
                </Row>
            )}
            <div className="button-container">
                <Button disabled={buttonDisabled || loading} onClick={handleSubmit} variant="dark">
                    {payment.mobile.confirm}
                </Button>
            </div>
        </Container>
    );
};

export default (props) => (
    //<Elements stripe={stripePromise}>
    <CreditCard {...props} />
    //</Elements>
);
