import React, { useEffect } from 'react';
import { StylingButton } from '../../../components';
import { trackEvent, MP_EVENTS } from 'services/mixpanel';

interface ILetGetStyling {
    title: string;
    helpText: string;
    hyperText: string;
}
const trackStylingClick = () =>
    trackEvent({ name: MP_EVENTS.HOW_IT_WORKS_GET_STYLING, properties: {} });
const trackHelpCenterClick = () =>
    trackEvent({ name: MP_EVENTS.HOW_IT_WORKS_HELP_CENTER, properties: {} });

const registerLinksEvents = () => {
    const stylingButton = document.querySelector('.styling-button.how-it-works');
    const helpCenterButton = document.querySelector('.helpCenter');
    if (stylingButton && helpCenterButton) {
        stylingButton.addEventListener('click', trackStylingClick);
        helpCenterButton.addEventListener('click', trackHelpCenterClick);
    }
};

const LetGetStyling = (props: { data: ILetGetStyling }) => {
    const data = props.data;
    useEffect(() => {
        registerLinksEvents();
    }, []);
    return (
        <div className={'how-it-works section styling'}>
            <div className="letsGet">
                <div className="styleTitle">{data.title}</div>
                <StylingButton className="how-it-works" />
                <div className="helpText">
                    {data.helpText}{' '}
                    <a target="_blank" href="https://wishi.zendesk.com/" className="helpCenter">
                        {data.hyperText}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default LetGetStyling;
