import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { nextQuestion, prevQuestion, replyQuestion } from '../../redux/reducers/quiz/actions';
import { loadInbox } from '../../routes/Inbox/store/actions';
import _Question from './Question';
import _Quiz from './Quiz';
import _Welcome from './Welcome';

const mapStateToProps = ({
    users: { twilioInit },
    user: { user },
    booking: { stylist },
    quiz: { questions, session_id, done, loading },
    inbox: { messages },
    payments: { price }
}) => ({
    user,
    stylist,
    session_id,
    done,
    questions,
    messages,
    twilioInit,
    loading,
    price
});

const mapDispatchToProps = (dispatch) => ({
    prevQuestion: () => dispatch(prevQuestion()),
    nextQuestion: (bodyIssuesFlag) => dispatch(nextQuestion(bodyIssuesFlag)),
    replyQuestion: (answer) => dispatch(replyQuestion(answer)),
    loadInbox: () => loadInbox(dispatch),
    selectChannel: (channel) => {}
});

export const Question = withRouter(connect(mapStateToProps, mapDispatchToProps)(_Question));
export const Welcome = withRouter(connect(mapStateToProps, mapDispatchToProps)(_Welcome));
export const Quiz = withRouter(connect(mapStateToProps, mapDispatchToProps)(_Quiz));
