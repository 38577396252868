import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    getUserCards,
    giftCardError,
    payGiftCard,
    updateGiftCard,
    updateGiftCardState
} from '../../../redux/reducers/payments/actions';
import CheckoutForm from './CheckoutForm';
import Customers from './Customers';
import Designs from './Designs';
import Details from './Details';
import Header from './Header';
import Info from './Info';
import Overview from './Overview';
import Steps from './Steps';
import Summary from './Summary';

const mapStateToProps = ({ user: { user }, payments: { giftcard, error } }) => ({
    user,
    giftcard,
    error
});

const mapDispatchToProps = (dispatch) => ({
    updateGiftCard: (giftcard) => dispatch(updateGiftCard(giftcard)),
    updateGiftCardState: (state) => dispatch(updateGiftCardState(state)),
    getUserCards: () => dispatch(getUserCards()),
    payGiftCard: (params) => dispatch(payGiftCard(params)),
    giftCardError: (error) => dispatch(giftCardError(error))
});

const components = {
    Header: withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)),
    Steps: withRouter(connect(mapStateToProps, mapDispatchToProps)(Steps)),
    Info: connect(mapStateToProps, mapDispatchToProps)(Info),
    Customers: withRouter(connect(mapStateToProps, mapDispatchToProps)(Customers)),
    Designs: connect(mapStateToProps, mapDispatchToProps)(Designs),
    Details: connect(mapStateToProps, mapDispatchToProps)(Details),
    CheckoutForm: connect(mapStateToProps, mapDispatchToProps)(CheckoutForm),
    Overview: connect(mapStateToProps, mapDispatchToProps)(Overview),
    Summary: connect(mapStateToProps, mapDispatchToProps)(Summary)
};

export default components;
