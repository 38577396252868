import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useEffect } from 'react';
import { Card, Container } from 'react-bootstrap';
import Slider from 'react-slick';
import { ReviewItem } from 'types/utils';
import { toggleModal } from 'store/ui/actions';
import { useWithDispatch } from 'hooks';
import { useIsMobile } from 'hooks';
import { trackEvent } from 'services/mixpanel';
import { MP_EVENTS } from 'services/mixpanel';
import { ReviewBody } from 'components/Review';

interface IReviewsCarousel {
    items: ReviewItem[];
}

interface ResponsiveObject {
    breakpoint: number;
    settings: 'unslick' | { ['slidesToShow']: number };
}

interface ISliderSettings {
    className: string;
    dots: boolean;
    infinite: boolean;
    centerMode: boolean;
    centerPadding: string;
    speed: number;
    slidesToShow: number;
    slidesToScroll: number;
    autoplay: boolean;
    arrows: boolean;
    responsive?: ResponsiveObject[];
}

const ReviewsCarousel: React.FC<IReviewsCarousel> = ({ items }) => {
    const toggleModalAction = useWithDispatch(toggleModal);
    const isMobile = useIsMobile();
    const sliderSettings: ISliderSettings = {
        className: 'reviews-carousel',
        dots: false,
        infinite: true,
        centerMode: isMobile ? true : false,
        centerPadding: '30px',
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: isMobile ? 1 : 2,
        autoplay: false,
        arrows: !isMobile,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1060,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    const mixpanelTracker = (eventName: string) => {
        trackEvent({ name: eventName, properties: {} });
    };

    const touchCarouselEvent = () => {
        const reviewsCarouselObj = document.querySelector('.reviews-carousel') as HTMLElement;
        if (reviewsCarouselObj) {
            reviewsCarouselObj.addEventListener(
                'click',
                () => mixpanelTracker(MP_EVENTS.HOW_IT_WORKS_REVIEWS_NAVIGATION),
                { once: true }
            );
        }
    };

    useEffect(() => {
        touchCarouselEvent();
    }, []);

    return (
        <Container fluid className="carousel">
            <Slider {...sliderSettings}>
                {items.map((review, idx) => (
                    <Card key={idx} className="reviews-section reviewCard">
                        <Card.Body>
                            <ReviewBody review={review} index={idx} type={'reviewCard'} />
                        </Card.Body>
                    </Card>
                ))}
            </Slider>
        </Container>
    );
};
export default ReviewsCarousel;
