import { History } from 'history';

import { Config, Cookies, Style, Tracking, User, UserAuth } from '.';
import * as mixpanel from './mixpanel';
import { deleteSDKAccessToken, setSDKAccessToken } from './sdk';
import { sentryException } from './SentryLogging';
import { getUserToken } from './utils/token-utils';

export interface ICredentials {
    email: string;
    password: string;
    remember: boolean;
    token?: string;
    facebook_token?: string;
    googleAuthCode?: string;
    gender?: string;
    brands?: string;
    styles?: { val: { name: string; rate: number } };
    tags?: string;
}
const prepCredentials = (credentials: Partial<ICredentials>) => {
    const { email, password, remember, token } = credentials;
    let loginCredentials;

    if (token) {
        loginCredentials = { token };
    } else if (credentials.facebook_token || credentials.googleAuthCode) {
        loginCredentials = credentials;
    } else {
        loginCredentials = { email, password, remember };
    }
    return loginCredentials;
};

const login = async (credentials: Partial<ICredentials>) => {
    try {
        const loginCredentials = prepCredentials(credentials);
        const result = await UserAuth.login(loginCredentials);
        const uuid = result?.data.data ? result.data.data.uuid : result?.data.user_uuid;
        const sdkAccessToken = result?.data?.accessToken;

        sdkAccessToken && setSDKAccessToken(sdkAccessToken);

        if (result?.data?.code !== 401) {
            const token = result?.data.token; /* || getUserToken()*/
            const { data } = await User.get(uuid, token);

            mixpanel.setMixpanelPeopleData(data);

            localStorage.setItem(
                'user',
                JSON.stringify({
                    isAdmin: data.isAdmin || data.is_admin,
                    uuid: data.user_uuid,
                    token,
                    fb_user: data.fb_user
                })
            );
            Cookies.set(
                'user',
                {
                    isAdmin: data.isAdmin || data.is_admin,
                    uuid: data.user_uuid,
                    token,
                    fb_user: data.fb_user
                },
                {
                    expires: credentials.remember ? 365 : null,
                    path: '/'
                }
            );
            Tracking.tag({ event: 'ws.signIn', user_id: data.user_uuid });
            Tracking.track(
                { id: data.user_uuid, device: `${Config.isMobile() ? 'mobile-' : ''}web` },
                token
            );
            return {
                ...data,
                token
            };
        }
        sentryException(new Error('authentication failed'), 'authentication failed');
    } catch (err) {
        if ((err as any)?.response)
            throw {
                message: (err as any).response.data.message,
                errors: (err as any).response.data.errors
            };
        throw {
            errors: (err as any).errors
        };
    }
};

const logout = (history?: History) => {
    localStorage.removeItem('user');
    Cookies.remove('user', { path: '/' });
    Cookies.remove('user', { path: '/app' });
    deleteSDKAccessToken();

    if (history) {
        history.push('/');
    }
    return;
};

const signup = async (credentials: ICredentials) => {
    try {
        const result = await UserAuth.signup(credentials);
        const sdkAccessToken = result.data.accessToken;
        const uuid = result.data.data ? result.data.data.uuid : result.data.user_uuid;
        const token = result.data.token;
        const { data } = await User.get(uuid, token);

        sdkAccessToken && setSDKAccessToken(sdkAccessToken);

        Cookies.set(
            'user',
            {
                isAdmin: data.is_admin,
                uuid,
                token: result.data.token,
                fb_user: data.fb_user
            },
            {
                expires: 1,
                path: '/'
            }
        );
        if (credentials?.gender && credentials?.styles && credentials?.brands) {
            const { gender, styles, brands, tags } = { ...credentials };
            const brandsArray = brands?.split(',');
            const bodyTypesArray = tags !== undefined ? tags?.split(',') : undefined;
            const bodyTags: { tag_uuid: string; is_set: number }[] = [];
            if (bodyTypesArray) {
                bodyTypesArray.forEach((tag) => {
                    bodyTags.push({ tag_uuid: tag, is_set: 1 });
                });
            }
            Style.updateStyle(uuid, {
                gender,
                styles,
                brandsArray,
                tag_uuids: bodyTags[0].tag_uuid !== '' ? bodyTags : undefined
            });
        }
        Tracking.facebook('track', 'CompleteRegistration', {
            device: Config.isMobile() ? 'mobile' : 'desktop',
            signup_with: credentials.facebook_token ? 'facebook' : 'email'
        });
        Tracking.google({
            type: 'event',
            event: 'Funnel',
            data: { event_category: 'completeRegistration' }
        });
        Tracking.google({
            type: 'event',
            event: 'conversion',
            data: { send_to: 'AW-870964131/4zBACLWwnKcBEKO3p58D' }
        });
        Tracking.tag({ event: 'ws.completeRegistration', user_id: uuid });
        return {
            ...data,
            token: result?.data.token || getUserToken(),
            new_user: true
        };
    } catch (err) {
        // debugger;
        throw {
            message: (err as any).response.data.message,
            errors: (err as any).response.data.errors
        };
    }
};
export default {
    login,
    signup,
    logout
};
