import './style.scss';

import React from 'react';
import { Image } from 'react-bootstrap';

import { logo } from '../../content';

const Loader = () => (
    <div className="loader">
        <Image src={logo.w} />
        <div className="spinner" />
    </div>
);

export default Loader;
