import './style.scss';

import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { match } from '../../content';
import FilterItem from './FilterItem';

const FilterSearch = ({ options = [], filterKey, selected, onChange }) => {
    const [items, setItems] = useState([]);
    const [text, setText] = useState('');
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        setItems(options);
    }, [options]);

    useEffect(() => {
        setItems(options.filter((item) => item.key.toLowerCase().includes(text.toLowerCase())));
    }, [text]);

    const onInputChange = (e) => setText(e.target.value);

    return (
        <Col className="filter-search">
            <Form.Group>
                <div className="magnifier" />
                <Form.Control type="text" value={text} onChange={onInputChange} />
                {text.length > 0 && <div className="close" onClick={() => setText('')} />}
            </Form.Group>
            {items.slice(0, showAll ? items.length : 7).map((item) => {
                const isSelected = selected.filter((filter) => filter.key === item.key).length > 0;
                return (
                    <FilterItem
                        key={item.key}
                        filterKey={filterKey}
                        type="checkbox"
                        isSelected={isSelected}
                        item={item}
                        onChange={onChange}
                    />
                );
            })}
            {items.length > 7 && (
                <Row>
                    <Col className="show-all" onClick={() => setShowAll(!showAll)}>
                        {!showAll
                            ? match.filters.showAll.replace('%length%', items.length)
                            : match.filters.showLess}
                    </Col>
                </Row>
            )}
        </Col>
    );
};

export default FilterSearch;
