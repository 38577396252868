import React, { useState } from 'react';
import { Accordion, Button, Card, Col, Container, Row } from 'react-bootstrap';

const Faq = ({ data }) => {
    const [opened, setOpened] = useState([]);
    return (
        <Row className="faq">
            <Container>
                <Row>
                    <Col>
                        <p className="section-title">{data.title}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {data.questions.map(({ question, answer }, index) => (
                            <Accordion className="question" key={index}>
                                <Card className={opened.includes(index) ? 'opened' : ''}>
                                    <Card.Header>
                                        <Accordion.Toggle
                                            as={Button}
                                            variant="link"
                                            eventKey={index}
                                            onClick={() =>
                                                setOpened(
                                                    opened.includes(index)
                                                        ? opened.filter((i) => i !== index)
                                                        : [...opened, index]
                                                )
                                            }
                                        >
                                            {question}
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={index}>
                                        <Card.Body>
                                            <div dangerouslySetInnerHTML={{ __html: answer }} />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        ))}
                    </Col>
                </Row>
            </Container>
        </Row>
    );
};

export default Faq;
