import PrivateRoute from 'components/PrivateRoute';
import { useIsMobile, useWithDispatch } from 'hooks';
import { useInitCommonReducer } from 'hooks/app';
import { useFlags, useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ICredentials } from 'services/auth-service';
import { toggleZEWidget } from 'store/common/actions';
import { initalizedTwilio } from 'store/services/actions';
import { login } from 'store/user/actions';
import { useUserStore } from 'store/user/reducer';
import { Client } from 'types/user';

import { Modal, Toast } from './components';
import Routes from './routes';
import { Cookies, Tracking } from './services';
import { setMixpanelPeopleData } from './services/mixpanel';
import { extractUserData } from './services/utils/user-utils';
import * as Views from './views';

const getLoginToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('token');
    const cookieToken = Cookies.get('user')?.token;
    const storageToken = JSON.parse(localStorage.getItem('user') || '{}')?.token;
    return urlToken || cookieToken || storageToken;
};

const App: React.FC = () => {
    const path = useRef<string | null>(null);
    const loggingIn = useRef(false);
    const twilioInitialized = useRef(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const location = useLocation();
    const user = useUserStore((store) => store?.user);
    const flags = useFlags();
    const loginAction = useWithDispatch(login);
    const initalizedTwilioAction = useWithDispatch(initalizedTwilio);
    const toggleZEWidgetAction = useWithDispatch(toggleZEWidget);
    const howItWorksFlag = flags.howItWorks;
    const consumerStylistMatch = flags.consumerStylistMatch;
    const loginToken = getLoginToken();
    const ldClient = useLDClient();
    const isMobile = useIsMobile();

    const loginWithToken = useCallback(
        async (credentials: Partial<ICredentials>) => {
            const loggedInUser = await loginAction(credentials);

            if (loggedInUser) {
                const userDetails = extractUserData(loggedInUser as Client);
                if (ldClient && userDetails) {
                    await ldClient.identify(userDetails, undefined);
                    setIsLoaded(true);
                }
            } else {
                setIsLoaded(true);
            }
        },
        [loginAction, ldClient, setIsLoaded]
    );

    useInitCommonReducer();

    if (!twilioInitialized.current && user) {
        const { uuid, token } = Cookies.get('user') || {};

        if (uuid && uuid === user.user_uuid && token) {
            twilioInitialized.current = true;
            initalizedTwilioAction();
        }
    }

    useEffect(() => {
        if (!loggingIn.current && !user && ldClient) {
            loggingIn.current = true;

            if (loginToken) {
                loginWithToken({ token: loginToken });
            } else {
                setIsLoaded(true);
            }
        }
    }, [user, ldClient, loginToken, loginWithToken, setIsLoaded]);

    useEffect(() => {
        const { pathname } = location;
        if (pathname !== path.current) {
            path.current = pathname;

            window.scrollTo(0, 0);
            Tracking.tag({
                event: 'ws.virtualPageView',
                pagePath: pathname,
                pageTitle: document.title
            });
            if (!isMobile && process.env.NODE_ENV === 'production') {
                toggleZEWidgetAction(pathname);
            }
        }
    }, [location, isMobile, toggleZEWidgetAction]);

    useEffect(() => {
        if (ldClient && user) {
            const userDetails = extractUserData(user as Client);

            userDetails && ldClient.identify(userDetails, undefined);
        }
    }, [user, ldClient]);

    useEffect(() => {
        if (user) {
            setMixpanelPeopleData(user);
        }
    }, [user]);

    return (
        <div className="app">
            {isLoaded && ldClient && (
                <Switch>
                    <Route exact path="/">
                        <Views.Landing />
                    </Route>
                    {howItWorksFlag && (
                        <Route exact path="/how-it-works" component={Routes.HowItWorks} />
                    )}

                    <Route path="/onboarding/:step?">
                        <Views.OnBoarding />
                    </Route>

                    {consumerStylistMatch ? (
                        <PrivateRoute
                            exact
                            path="/stylistSearch"
                            component={Routes.StylistListPage}
                        />
                    ) : (
                        <PrivateRoute exact path="/stylistSearch" component={Routes.StylistMatch} />
                    )}
                    <Route path="/stylist/:id/profile">
                        <Views.Stylist />
                    </Route>
                    <Route exact path="/terms">
                        <Views.Terms />
                    </Route>
                    <Route exact path="/privacy">
                        <Views.Privacy />
                    </Route>
                    <Route exact path="/pricing">
                        <Routes.Pricing />
                    </Route>
                    <Route exact path="/our-story">
                        <Views.OurStory />
                    </Route>
                    <PrivateRoute exact path="/goals" component={Views.GoalsSelect} />
                    <PrivateRoute exact path="/plans" component={Views.GoalsPlans} />
                    <PrivateRoute exact path="/checkout" component={Views.GoalsCheckout} />
                    <PrivateRoute
                        exact
                        path="/quiz"
                        component={flags.newQuizB2C ? Views.Quiz : Views.QuizLegacy}
                    />
                    <PrivateRoute exact path="/thank-you" component={Views.QuizWelcome} />
                    <Route exact path="/gift-cards">
                        <Views.GiftCardsMain />
                    </Route>
                    <Route exact path="/gift-cards/checkout">
                        <Views.GiftCardsCheckout />
                    </Route>
                    <Route exact path="/gift-cards/confirmation">
                        <Views.GiftCardConfirmation />
                    </Route>
                    <Route exact path="/feed">
                        <Views.FeedPage />
                    </Route>
                    <Route exact path="/feed/outfit/:outfit_uuid">
                        <Views.OutfitPage />
                    </Route>
                    <Route exact path="/feed/outfit/:outfit_uuid/item/:item_uuid">
                        <Views.Item />
                    </Route>
                    <PrivateRoute
                        exact
                        path="/feed/outfit/:outfit_uuid/item/:item_uuid/checkout"
                        component={Views.ItemCheckout}
                    />
                    <PrivateRoute
                        exact
                        path="/feed/outfit/:outfit_uuid/item/:item_uuid/confirmation"
                        component={Views.ItemConfirmation}
                    />
                    <Route exact path="/item/:item_uuid">
                        <Views.Item />
                    </Route>
                    <PrivateRoute
                        exact
                        path="/item/:item_uuid/checkout"
                        component={Views.ItemCheckout}
                    />
                    <PrivateRoute
                        exact
                        path="/item/:item_uuid/confirmation"
                        component={Views.ItemConfirmation}
                    />
                    <PrivateRoute exact path="/favorites" component={Views.Favorites} />
                    <Route exact path="/favorites/outfit/:outfit_uuid">
                        <Redirect to="/favorites" />
                    </Route>
                    <PrivateRoute
                        exact
                        path="/favorites/outfit/:outfit_uuid/item/:item_uuid"
                        component={Views.Item}
                    />
                    <PrivateRoute
                        exact
                        path="/favorites/outfit/:outfit_uuid/item/:item_uuid/checkout"
                        component={Views.ItemCheckout}
                    />
                    <PrivateRoute
                        exact
                        path="/favorites/outfit/:outfit_uuid/item/:item_uuid/confirmation"
                        component={Views.ItemConfirmation}
                    />
                    <PrivateRoute exact path="/shopping-list" component={Views.ShoppingList} />
                    <Route exact path="/shopping-list/outfit/:outfit_uuid">
                        <Redirect to="/shopping-list" />
                    </Route>
                    <PrivateRoute
                        exact
                        path="/shopping-list/item/:item_uuid"
                        component={Views.Item}
                    />
                    <PrivateRoute
                        exact
                        path="/shopping-list/item/:item_uuid/checkout"
                        component={Views.ItemCheckout}
                    />
                    <PrivateRoute
                        exact
                        path="/shopping-list/item/:item_uuid/confirmation"
                        component={Views.ItemConfirmation}
                    />
                    <PrivateRoute exact path="/inbox" component={Routes.Inbox} />
                    <PrivateRoute exact path="/chat/:stylist_id/:user_id" component={Views.Chat} />
                    <Route
                        path="/gifts"
                        render={(props) => <Routes.Campaign {...props} campaign="gifts" />}
                    />
                    <Route
                        path="/wedding"
                        render={(props) => <Routes.Campaign {...props} campaign="wedding" />}
                    />
                    <PrivateRoute path="/orders" component={Views.Orders} />
                    <PrivateRoute path="/profile/:tab?" component={Views.Profile} />
                    <PrivateRoute path="/settings/:tab?" component={Views.Settings} />
                    <Route>
                        <Redirect to="/" />
                    </Route>
                </Switch>
            )}
            <Modal />
            <Toast />
        </div>
    );
};

export default withLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLEY_SDK_KEY ?? '',
    user: {
        key: '00000000-0000-0000-a'
    }
})(App);
