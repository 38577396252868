import './style.scss';

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Landing, Shopping } from './';

const Gifts = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} children={<Shopping />} />
        </Switch>
    );
};

export default Gifts;
