import './style.scss';

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { giftcards } from '../../../content';

const Summary = ({ giftcard }) => {
    const giftcardPackage = giftcards.packages.find((p) => p.price === giftcard.amount);

    return (
        <Container className="summary">
            <Row>
                <Col>
                    <p>
                        {giftcards.checkout.giftCardCheckout.replace(
                            '%label%',
                            giftcardPackage.label
                        )}
                    </p>
                    <p>
                        {giftcards.checkout.currency}
                        {giftcard.amount}
                    </p>
                </Col>
            </Row>
            {giftcard.additionalAmount > 0 && (
                <Row>
                    <Col>
                        <p>{giftcards.checkout.additionalAmount}</p>
                        <p>
                            {giftcards.checkout.currency}
                            {giftcard.additionalAmount}
                        </p>
                    </Col>
                </Row>
            )}
            <Row className="total">
                <Col>
                    <p>{giftcards.checkout.total}</p>
                    <p>
                        {giftcards.checkout.currency}
                        {giftcard.amount + giftcard.additionalAmount}
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default Summary;
