import './style.scss';

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { Loader, Orders } from '../../../components';
import { settings as texts } from '../../../content';
import Breadcrumbs from './Breadcrumbs';

const Orderss = ({ loading, user, orders }) => {
    const isEmpty = !loading && user && !orders.length;
    return (
        <Container className="settings-orders" fluid>
            {loading && <Loader />}
            <Row>
                <Col className="header">
                    <Breadcrumbs />
                    <label>{texts.orders.title}</label>
                    {isEmpty && <p>{texts.orders.empty}</p>}
                </Col>
            </Row>
            <Orders />
        </Container>
    );
};
export default Orderss;
