import './style.scss';

import React, { useState } from 'react';
import { Accordion, Button, Col, Container, Row } from 'react-bootstrap';

import { gifts as texts } from '../../../campaigns';
import Filter from './Filter';

const MobileFilters = ({ filters, selected, toggleMobileCollapse, onChange, onClear }) => {
    const [collapsed, setCollapsed] = useState(['stylist_uuid']);

    const setCollapsedFilters = (key) => {
        if (collapsed.includes(key)) {
            setCollapsed(collapsed.filter((item) => item !== key));
        } else {
            setCollapsed([...collapsed, key]);
        }
    };

    const clearFilters = () => {
        onClear();
        toggleMobileCollapse();
    };

    return (
        <Container className="gifts-mobile-filters no-padding" fluid>
            <Row>
                <div className="close d-block d-md-none" onClick={toggleMobileCollapse} />
            </Row>
            <Row className="filters-container">
                <Col className="filters-title">{texts.shopping.filters.title}</Col>
                <Col>
                    <Accordion className="filters-list">
                        {Object.keys(filters).map((key) => (
                            <Filter
                                key={key}
                                type={key}
                                filter={filters[key]}
                                onClick={() => setCollapsedFilters(key)}
                                onChange={onChange}
                                isCollaped={collapsed.includes(key)}
                                selected={selected[key]}
                            />
                        ))}
                    </Accordion>
                </Col>
                <Container>
                    <Row className="filter-btns">
                        <Col xs={5} className="clear" onClick={clearFilters}>
                            {texts.shopping.filters.clear}
                        </Col>
                        <Col xs={7}>
                            <Button variant="dark" onClick={toggleMobileCollapse}>
                                {texts.shopping.filters.results}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </Container>
    );
};
export default MobileFilters;
