import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { ActionTypes, InboxState, Actions } from './types';

const initialState: InboxState = {
    indicators: {
        loading: false,
        error: null
    },
    messages: [],
    channel: ''
};

const inboxReducer = (state = initialState, action: Actions): InboxState => {
    switch (action.type) {
        case ActionTypes.LOAD_INBOX_MESSAGES_REQUEST: {
            return {
                ...state,
                indicators: {
                    ...state.indicators,
                    loading: true
                }
            };
        }
        case ActionTypes.LOAD_INBOX_MESSAGES_SUCCESS: {
            return {
                ...state,
                indicators: {
                    ...state.indicators,
                    loading: false
                },
                messages: action.payload.map((message) => ({
                    ...message,
                    isSubscription: message.subscription_id !== null
                }))
            };
        }
        case ActionTypes.LOAD_INBOX_MESSAGES_FAILURE: {
            return {
                ...state,
                indicators: {
                    ...state.indicators,
                    loading: false
                }
            };
        }
        case ActionTypes.SELECT_CHANNEL: {
            return {
                ...state,
                channel: action.payload
            };
        }
        case ActionTypes.CLEAR_INBOX_MESSAGES: {
            return {
                ...state,
                messages: []
            };
        }
        default:
            return state;
    }
};
export const name = 'inbox';
export const useInboxStore: TypedUseSelectorHook<InboxState> = (selector, ...args) =>
    useSelector((store: any) => selector(store[name]), ...args);
export default inboxReducer;
