import './style.scss';

import React from 'react';

import { profile as texts } from '../../../content';

export default ({ section }) => {
    return (
        section && (
            <div className="profile-empty">
                <p>{texts.empty.split('%section%').join(section)}</p>
                <img src={texts.emptyIcon} alt="" />
            </div>
        )
    );
};
