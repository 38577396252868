import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleModal } from 'store/ui/actions';

import { updateStylistLook } from '../../redux/reducers/stylists/actions';
import {
    addToCart,
    removeFromCart,
    setCurrentOutfit,
    updateFavoriteLook
} from '../../redux/reducers/users/actions';
import Outfit from './Outfit';

const mapStateToProps = ({ users: { cart, currentOutfit }, user: { user } }) => ({
    user,
    cart,
    currentOutfit
});

const mapDispatchToProps = (dispatch) => ({
    addToCart: (item, outfitSource) => dispatch(addToCart(item, outfitSource)),
    removeFromCart: (item) => dispatch(removeFromCart(item)),
    updateFavoriteLook: (outfit, source) => dispatch(updateFavoriteLook(outfit, source)),
    toggleModal: (data) => toggleModal(dispatch, data),
    updateStylistLook: (outfit_uuid, look) => dispatch(updateStylistLook(outfit_uuid, look)),
    setCurrentOutfit: (outfit) => dispatch(setCurrentOutfit(outfit))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Outfit));
