import './style.scss';

import React, { useEffect, useState } from 'react';
import { Col, Container, Form } from 'react-bootstrap';
import { registerProperties } from 'services/mixpanel';
import { quiz } from '../../content';

const Birthday = ({ onChange }) => {
    const START_YEAR = 1940;
    const MAX_YEAR = 2006;
    const MONTHS = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    const [years, setYears] = useState([]);
    const [days, setDays] = useState([]);
    const [day, setDay] = useState();
    const [month, setMonth] = useState();
    const [year, setYear] = useState();

    useEffect(() => {
        let arr = [];
        for (let year = START_YEAR; year <= MAX_YEAR; year++) arr.push(year);
        setYears(arr);

        arr = [];
        for (let day = 1; day <= 31; day++) arr.push(day);
        setDays(arr);
    }, []);

    useEffect(() => {
        if (year && month && day) {
            const d = new Date(year, Number(month) - 1, day);
            const isValidDate = d.getMonth() + 1 == Number(month);
            if (isValidDate) {
                const birthDate = `${year}-${month < 10 ? '0' : ''}${month}-${
                    day < 10 ? '0' : ''
                }${day}`;
                registerProperties({
                    Birthdate: birthDate
                });
                onChange({
                    reply: birthDate
                });
            } else {
                onChange({
                    reply: ''
                });
            }
        }
    }, [day, month, year]);

    return (
        <Container className="birthday">
            <Form>
                <Form.Row>
                    <Col xs={6}>
                        <Form.Control
                            id="birthday-month"
                            as="select"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                        >
                            <option value="" disabled selected>
                                {quiz.questions.birthday.month}
                            </option>
                            {MONTHS.map((month, index) => (
                                <option key={month} value={index + 1}>
                                    {month}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col xs={6}>
                        <Form.Control
                            id="birthday-day"
                            as="select"
                            value={day}
                            onChange={(e) => setDay(e.target.value)}
                        >
                            <option value="" disabled selected>
                                {quiz.questions.birthday.day}
                            </option>
                            {days.map((day) => (
                                <option key={day} value={day}>
                                    {day}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Control
                            id="birthday-year"
                            as="select"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            onFocus={() => (!year ? setYear(1980) : '')}
                        >
                            <option value="" disabled selected>
                                {quiz.questions.birthday.year}
                            </option>
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                </Form.Row>
            </Form>
        </Container>
    );
};

export default Birthday;
