import './style.scss';

import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const FacebookButton = ({ location: { pathname }, user, onboarding, onDone }) => {
    const [inProcess, setInProcess] = useState(false);
    // facebookLoginHandler = (accessToken, userID) => {
    // 		this.FB.api('/' + userID + '?fields=email,id,first_name,last_name,birthday,location,gender', async(res) => {
    // let FBData = result.data;
    // FBData.fb_user = true;
    //
    // if (FBData.new_user) {
    // 	if ((location.host === 'www.wishi.me' || location.host === 'wishi.me') && location.url !== '') {
    // 		const dataLayer = window.dataLayer || [];
    // 		dataLayer.push({
    // 			'event': 'SignUpSource',
    // 			'SignUpSource': 'Facebook'
    // 		});
    //
    //
    // 		});
    // };

    const responseFacebook = async (res) => {
        setInProcess(false);

        let credentials = {
            email: res.email || null,
            facebook_id: res.id,
            first_name: res.first_name,
            last_name: res.last_name,
            facebook_token: res.accessToken,
            birthday: res.birthday || null,
            city: res.location ? res.location.name.split(',')[0] : null,
            country: res.location ? res.location.name.split(',')[1] : null,
            gender: res.gender,
            picture: res.picture ? res.picture.data.url : null,
            source: ''
        };

        if (pathname.includes('WISHIxC21')) credentials.source = 'Century21';
        else if (pathname.includes('MacysxWISHI')) credentials.source = 'macys';
        else if (pathname.includes('/RSVP')) credentials.source = 'RSVP';
        else if (pathname.includes('/profile')) credentials.source = 'profileSection';

        if (user) {
            credentials = {
                ...credentials,
                gender: user.gender,
                age: user.age,
                stylist_level: user.stylist_level,
                looks: user.looks,
                brands: user.brands,
                tags: user.tags,
                styles: user.styles
            };
        }
        if (onboarding) {
            credentials = {
                ...credentials,
                gender: onboarding.gender,
                styles: onboarding.styles,
                brands: onboarding.brands.map((brand) => brand.uuid).join(','),
                tags: onboarding.bodyTypes.map((type) => type.tag_uuid).join(',')
            };
        }

        if (res.email) onDone(credentials);
    };

    return (
        <FacebookLogin
            appId="608551625920580"
            autoLoad={false}
            scope="public_profile, email, user_birthday"
            fields="email,id,first_name,last_name,birthday,location,gender,picture"
            callback={responseFacebook}
            disableMobileRedirect={true}
            render={(renderProps) => (
                <Button
                    className="facebook-btn"
                    variant="info"
                    id="facebookButton"
                    disabled={renderProps.isDisabled || inProcess}
                    onClick={(e) => {
                        setInProcess(true);
                        renderProps.onClick(e);
                    }}
                >
                    <FontAwesomeIcon icon={faFacebookF} className="facebook-icon" />
                    CONTINUE WITH FACEBOOK
                </Button>
            )}
        />
    );
};

export default FacebookButton;
